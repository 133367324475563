import React, { useState, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import CustomModal from '../../CustomModal';
import Markdown from '../../Markdown';
import InfoCircleIcon from '../../../assets/images/icons/info-circle-icon.svg';
import './styles.scss';

const QuestionHintComponent = ({ hints }) => {
	const [hintsModal, setHintsModal] = useState(false);

	const hintsCloseModal = () => setHintsModal(false);
	const hintsOpenModal = () => setHintsModal(true);

	if (isEmpty(hints)) return;

	const modalTitle = 'Hints';
	const modalContent = (
		<ol>
			{hints.map((e, index) => {
				return (
					<li key={index}>
						<Markdown>{e}</Markdown>
					</li>
				);
			})}
		</ol>
	);
	const modalFooterButtons = [
		{ label: 'Close', variant: 'danger', onClick: hintsCloseModal }
	];

	return (
		<>
			<ReactSVG
				src={InfoCircleIcon}
				className='info_icon'
				onClick={hintsOpenModal}
			/>
			<CustomModal
				title={modalTitle}
				content={modalContent}
				footerButtons={modalFooterButtons}
				show={hintsModal}
				handleClose={hintsCloseModal}
			/>
		</>
	);
};

export default memo(QuestionHintComponent);
