import { createSlice } from '@reduxjs/toolkit';
import { handleLogin, handleSignup, handleChangePassword } from '../auth';

const initialState = {
	isLoggedIn: false,
	error: false,
	loading: false,
	loginResponse: {},
	changePasswordResponse: {}
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.isLoggedIn = false;
			state.loading = false;
		},
		reset: (state) => {
			state.changePasswordResponse = {};
		}
	},
	extraReducers: {
		[handleLogin.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				localStorage.setItem('userToken', payload.response.data.data.token);
				state.isLoggedIn = true;
				state.loginResponse = payload.response.data;
			}
			if (payload.response.code === 'ERR_NETWORK') {
				state.loginResponse = { error: true, message: payload.response.message };
			}
			if (payload.response.response && payload.response.response.data) {
				state.loginResponse = payload.response.response.data;
			}
			state.loading = false;
		},
		[handleLogin.pending]: (state) => {
			state.loading = true;
		},
		[handleLogin.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},

		[handleSignup.fulfilled]: (state, { payload }) => {
			state.loginResponse = payload.response;
			if (payload.response.status === 200) {
				state.isLoggedIn = true;
			}
		},
		[handleSignup.pending]: (state) => {
			state.loading = true;
		},
		[handleSignup.rejected]: (state) => {
			state.error = true;
		},

		[handleChangePassword.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.isLoggedIn = true;
			}
			if (payload.response.response) {
				state.changePasswordResponse = payload.response.response;
			} else if (payload.response) {
				state.changePasswordResponse = payload.response.data;
			}
		},
		[handleChangePassword.pending]: (state) => {
			state.loading = true;
		},
		[handleChangePassword.rejected]: (state) => {
			state.error = true;
		}
	}
});

export const { logout, reset } = authSlice.actions;

export default authSlice.reducer;
