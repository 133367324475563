/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { isEmpty, findIndex, isNull } from "lodash";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import crossIcon from "../../../assets/images/cross.png";
import {
  questionCorrectnessType1,
  attemptedQCounterForMatchItems,
  correctQuestionValType2,
} from "../../../utils/practice";
import "./styles.scss";

const MatchItemsQ = ({
  data,
  value,
  indexValue,
  setFieldVal,
  fieldValue,
  name,
}) => {
  const handleAnswers = (data) => {
    if (data.ans_obj && data.ans_obj?.ans_key && data?.attempted_obj) {
      return data?.attempted_obj?.map((e, i) => {
        return { id: i, name: data.list_rhs[e] || "" };
      });
    }
    if (fieldValue) {
      return fieldValue?.map((e, i) => {
        return { id: i, name: data.list_rhs[e] || "" };
      });
    }
    return data?.list_lhs?.map((_, i) => {
      return { id: i, name: "" };
    });
  };

  const handleAnsToSubmit = () => {
    if (data.ans_obj && data.ans_obj?.ans_key && data?.attempted_obj) {
      return data?.attempted_obj?.map((e) => {
        return e;
      });
    }
    if (fieldValue) {
      return fieldValue?.map((e) => {
        return e;
      });
    }
    return data?.list_lhs?.map(() => {
      return null;
    });
  };

  const [answers, setAnswers] = useState(handleAnswers(data));
  const formOptions = data?.list_rhs?.map((value) => ({ name: value }));

  const [ans, setAns] = useState(false);
  const [ansToSubmit, setAnsToSubmit] = useState(handleAnsToSubmit());

  useEffect(() => {
    correctQuestionValType2(data, fieldValue, setFieldVal, name);
    attemptedQCounterForMatchItems(data, value, indexValue, fieldValue);
  }, [value, fieldValue]);

  const drop = (e, index) => {
    const nameNew = e;
    const ind = findIndex(formOptions, { name: e[0].name });
    if (!isEmpty(answers) && e[0].name && ans && ind >= 0) {
      nameNew[index + 1].name = e[0].name;
      const temp = ansToSubmit;
      temp[index] = ind;
      setAnsToSubmit(temp);
      nameNew.shift();
      setFieldVal(name, temp);
      return setAnswers(nameNew);
    } else if (isEmpty(answers[index].name)) {
      const temp = ansToSubmit;
      temp[index] = null;
      setAnsToSubmit(temp);
      setFieldVal(name, temp);
      if (!isEmpty(fieldValue) && fieldValue.every((ele) => ele === null)) {
        setFieldVal(name, null);
      }
      return setAnswers(nameNew);
    }
  };

  const drag = () => {
    setAns(false);
  };

  const start = (i) => {
    answers[i].name = "";
  };

  const startBack = () => {
    setAns(true);
  };

  const rhsDisable = (i) => {
    if (ansToSubmit?.includes(i)) {
      return "disable";
    } else {
      return "";
    }
  };

  const getLhs = (element, index) => {
    if (element?.includes("img")) {
      return (
        <div className="form_svg_lhs" key={index}>
          <div
            className="Lhs_svg"
            dangerouslySetInnerHTML={{ __html: element }}
          ></div>
        </div>
      );
    }
    return (
      <div className="formLHS" key={index}>
        <h6
          data-text={element}
          dangerouslySetInnerHTML={{ __html: element }}
        ></h6>
      </div>
    );
  };

  const getRhs = (element, i) => {
    if (element?.name?.includes("img")) {
      return (
        <div key={i} className={rhsDisable(i)}>
          <div
            id="rhs_svg"
            className="Rhs_svg"
            dangerouslySetInnerHTML={{ __html: element.name }}
          ></div>
        </div>
      );
    }
    return (
      <h6
        key={i}
        className={rhsDisable(i)}
        dangerouslySetInnerHTML={{ __html: element.name }}
      ></h6>
    );
  };

  const getAnswers = (element) => {
    if (element?.name?.includes("img")) {
      return (
        <div>
          <div
            id="ans_svg"
            className="answer_svg"
            dangerouslySetInnerHTML={{ __html: element.name }}
          ></div>
        </div>
      );
      <QueExplanationComponent data={data} explanations={data.explanations} />;
    }
    return (
      <h6
        className="question_options"
        dangerouslySetInnerHTML={{ __html: element.name }}
      ></h6>
    );
  };

  const handleStyle = (e, i) => {
    if (data?.list_rhs[0]?.includes("img")) {
      if (data.ans_obj) {
        return `question_answers_svg ${questionCorrectnessType1(data, i)}`;
      } else {
        return "question_answers_svg";
      }
    }
    if (data.ans_obj) {
      return `question_answers ${questionCorrectnessType1(data, i)}`;
    } else {
      return "question_answers";
    }
  };

  const handleAnsAfterSubmission = (data, i) => {
    if (isNull(data.attempted_obj)) return;
    if (isNull(data.attempted_obj[i])) return;
    if (data.list_rhs[data.attempted_obj[i]]?.includes("img")) {
      return (
        <div key={i}>
          <div
            id="ans_svg"
            className="answer_svg"
            dangerouslySetInnerHTML={{
              __html: data.list_rhs[data?.attempted_obj[i]],
            }}
          ></div>
        </div>
      );
    }
    return (
      <h6
        key={i}
        className="question_options"
        dangerouslySetInnerHTML={{
          __html: data.list_rhs[data?.attempted_obj[i]],
        }}
      ></h6>
    );
  };

  const rightAnsTooltip = (answer) => {
    if (!answer) return;
    if (answer?.includes("img")) {
      <div>
        <div
          id="ans_svg"
          className="answer_svg"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        ></div>
      </div>;
    }

    return (
      <h6
        className="question_options"
        dangerouslySetInnerHTML={{
          __html: answer,
        }}
      ></h6>
    );
  };

  const handleAnswerSection = (data) => {
    if (data.ans_obj) {
      return data.ans_obj.ans_key.map((e, i) => {
        return (
          <div key={i} className="question_answers_inner">
            <React.Fragment>
              <div className={handleStyle(data, i)}>
                {handleAnsAfterSubmission(data, i)}
              </div>
              {data?.ans_obj &&
                data?.ans_obj?.ugot_right &&
                data?.ans_obj?.ugot_right[i] === false && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${i}`}>
                        {rightAnsTooltip(
                          data?.list_rhs[data?.ans_obj?.ans_key[i]]
                        )}
                      </Tooltip>
                    }
                  >
                    <Button>
                      <img src={crossIcon} />
                    </Button>
                  </OverlayTrigger>
                )}
            </React.Fragment>
          </div>
        );
      });
    }
    return answers.map((e, i) => {
      return (
        <ReactSortable
          className={handleStyle(e, i)}
          key={i}
          list={answers}
          setList={(e) => drop(e, i)}
          group={{
            name: `sharable${data.qno}`,
            pull: "clone",
          }}
          onStart={() => start(i)}
        >
          {answers[i].name && getAnswers(e)}
        </ReactSortable>
      );
    });
  };

  return (
    <div className="miq_simple_display" id={`${data.q_type}_${data.qno}`}>
      <div className="miq_simple_display_header">
        <div className="question_number">
          <h6>{data.qno}</h6>
          <QuestionScoreComponent data={data} />
          <QueExplanationComponent
            data={data}
            explanations={data.explanations}
          />
        </div>
        <div className="content">
          <h4>
            For each LHS find the matching RHS. Drag a RHS to the correct box.
          </h4>
          <div className="icons">
            <QuestionHintComponent hints={data.hints} />
          </div>
        </div>
      </div>
      <div
        className={`drag_drop_page ${
          data?.list_rhs[0]?.includes("img") ||
          data?.list_lhs[0]?.includes("img")
            ? "with_svg"
            : ""
        }`}
      >
        <div className="miq_container">
          <Row className="drag_drop_wrapper">
            <Col md={4} className="mcq_lhs">
              <div
                className={
                  data?.list_lhs[0]?.includes("img")
                    ? "miq_container container_svg"
                    : "miq_container"
                }
              >
                {data?.list_lhs?.map((element, index) =>
                  getLhs(element, index)
                )}
              </div>
            </Col>
            <Col md={4} className="mcq_answers">
              <div
                className={
                  data?.list_rhs[0]?.includes("img")
                    ? "miq_container container_svg"
                    : "miq_container"
                }
              >
                {handleAnswerSection(data)}
              </div>
            </Col>
            <Col md={4} className="mcq_options">
              <div
                className={
                  data?.list_rhs[0]?.includes("img")
                    ? "miq_container container_svg"
                    : "miq_container"
                }
              >
                <ReactSortable
                  className="question_options"
                  list={formOptions}
                  setList={(e) => drag(e)}
                  group={{
                    name: `sharable${data.qno}`,
                    put: false,
                    pull: "clone",
                  }}
                  onStart={(e, f) => startBack(e, f)}
                  disabled={data.ans_obj}
                  sort={false}
                >
                  {formOptions?.map((element, i) => {
                    return getRhs(element, i);
                  })}
                </ReactSortable>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MatchItemsQ;
