/* eslint-disable */
import React from "react";
import { Form } from "react-bootstrap";
import { isArray, isEmpty } from "lodash";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import AnsMarkingComponent from "../AnsMarkingComponent";
import Markdown from "../../Markdown";
import useShowHideAns from "../../../hooks/useShowHideAns";
import "./styles.scss";

const MatrixMatchQ = ({
  data,
  fieldValue,
  setFieldVal,
  name,
  value,
  indexValue,
}) => {
  const { i: qIndex } = indexValue;

  const generateHTMLContent = (ctext) => {
    return <Markdown>{ctext}</Markdown>;
  };

  const getVal = () => {
    if (data?.row_ids && data?.col_ids) {
      const a = data.row_ids.map(() => {
        return data.col_ids.map(() => false);
      });
      return a;
    }
    return [];
  };

  const handleChange = (e, i, j) => {
    let temp = [];
    if (!isEmpty(fieldValue)) {
      temp = JSON.parse(JSON.stringify(fieldValue));
    } else {
      temp = getVal();
    }
    temp[i][j] = e.target.checked;
    setFieldVal(name, temp);
    if (
      value.list_sections &&
      value.list_sections[qIndex] &&
      value.list_sections[qIndex].dict_qno_attempted
    ) {
      value.list_sections[qIndex].dict_qno_attempted[data.qno] = true;
      if (
        isArray(temp) &&
        temp.every((subArray) => subArray.every((element) => element === false))
      ) {
        setFieldVal(name, null);
        if (value.list_sections[qIndex].dict_qno_attempted) {
          value.list_sections[qIndex].dict_qno_attempted[data.qno] = false;
        }
      }
    }
  };

  const getRowIndicatorStyle = (index) => {
    if (!useShowHideAns()) return;
    if (!isEmpty(data) && data.ans_obj && data.ans_obj.ugot_right?.[index]) {
      return "right";
    }
    if (
      !isEmpty(data) &&
      data.ans_obj &&
      data.ans_obj.ugot_right?.[index] === false
    ) {
      return "wrong";
    }
  };

  const getCheckboxGradedStyle = (i, j) => {
    if (!useShowHideAns()) return;
    if (!isEmpty(data) && data.ans_obj && data.ans_obj.ans_key?.[i]?.[j]) {
      return "question_ans_right";
    }
    if (
      !isEmpty(data) &&
      data.ans_obj &&
      data.ans_obj.ans_key?.[i]?.[j] === false
    ) {
      return "question_ans_wrong";
    }
  };

  return (
    <div className="matrix_match_display" id={`${data.q_type}_${data.qno}`}>
      <div>
        <div className="header_wrapper">
          <div className="question_number">
            <h6>{data.qno}</h6>
            <QuestionScoreComponent data={data} />
            <QueExplanationComponent
              data={data}
              explanations={data.explanations}
            />
            <QuestionHintComponent hints={data.hints} />
            <AnsMarkingComponent data={data} />
          </div>
          <div className="matrix_match_display_options_radio">
            {generateHTMLContent(data.q_I_II)}
            {/* <div className='matrix_options_table'>
							<table>
								<thead>
									<tr>
										<th></th>
										<th>{data?.head_I}</th>
										<th></th>
										{data?.head_II && <th>{data?.head_II}</th>}
									</tr>
								</thead>
								<tbody>
									{data?.list_I?.map((ele, index) => (
										<tr key={index}>
											<td>{`(${data.row_ids?.[index]})`}</td>
											<td>{generateHTMLContent(ele)}</td>
											{data?.list_II && (
												<>
													<td>{`(${data.col_ids?.[index]})`}</td>
													<td>{generateHTMLContent(data.list_II[index])}</td>
												</>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div> */}
            <div className="matrix_options_container">
              <div className="column_headings">
                {data?.col_ids?.map((el, index) => {
                  return (
                    <div key={index} className="single_column_heading">
                      {el}
                    </div>
                  );
                })}
              </div>
              <div className="option_with_rows">
                <div className="option_rows">
                  {data?.row_ids?.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className={`row_indicator ${getRowIndicatorStyle(
                          index
                        )}`}
                      >
                        {el}{" "}
                      </div>
                    );
                  })}
                </div>
                <div>
                  {data?.row_ids &&
                    data?.row_ids?.map((el, i) => {
                      return (
                        <div key={i} className="input-wrapper">
                          {data?.col_ids &&
                            data?.col_ids?.map((ele, j) => {
                              return (
                                <Form.Check key={j} inline>
                                  <div>
                                    <Form.Check.Input
                                      type="checkbox"
                                      id={`${data.qno}-${i}-${j}`}
                                      name={`${data.qno}-${i}-${j}`}
                                      onChange={(e) => handleChange(e, i, j)}
                                      checked={fieldValue?.[i]?.[j] || false}
                                      value={j}
                                      data-j={String.fromCharCode(65 + j)}
                                      disabled={data.ans_obj}
                                    />
                                    <Form.Check.Label
                                      htmlFor={`${data.qno}-${i}-${j}`}
                                      data-j={String.fromCharCode(65 + j)}
                                      className={getCheckboxGradedStyle(i, j)}
                                    >
                                      {ele}
                                    </Form.Check.Label>
                                  </div>
                                </Form.Check>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixMatchQ;
