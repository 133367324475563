import React from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';

export function PieChart () {
	const { signupInfo } = useSelector((state) => state.admin);

	const data = [
		['Task', 'Hours per Day'],
		['Basic', signupInfo ? signupInfo.total_users.basic : 50],
		['Premium', signupInfo ? signupInfo.total_users.premium : 50]
	];
	return (
		<Chart
			chartType="PieChart"
			data={data}
			width={'100%'}
			height={'250px'}
		/>
	);
}
