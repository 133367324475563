/* eslint-disable init-declarations */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty, has, isNull, isArray } from 'lodash';
import { Button, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Timer from '../../../components/PracticeSectionComponents/Timer';
import { useSpeechSynthesis } from 'react-speech-kit';
import TextAnswerQ from '../../../components/QuestionsGroup/TextAnswerQ';
import DictationQ from '../../../components/QuestionsGroup/DictationQ';
import MathExpressionQ from '../../../components/QuestionsGroup/MathExpressionQ';
import MultipleChoiceQ from '../../../components/QuestionsGroup/MultipleChoiceQ';
import UniqueChoiceQ from '../../../components/QuestionsGroup/UniqueChoiceQ';
import TrueFalseQ from '../../../components/QuestionsGroup/TrueFalseQ';
import FillGroupQ from '../../../components/QuestionsGroup/FillGroupQ';
import FillInlineQ from '../../../components/QuestionsGroup/FillInlineQ';
import FillBlankQ from '../../../components/QuestionsGroup/FillBlankQ';
import FillImgQ from '../../../components/QuestionsGroup/FillImgQ';
import MatchItemsQ from '../../../components/QuestionsGroup/MatchItemsQ';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import CustomModal from '../../../components/CustomModal';
import Loader from '../../../components/Loader';
import {
	getQuestionIndex,
	getFieldVal,
	getQuestionNumber
} from '../../../utils/practice';
import nextIcon from '../../../assets/images/icons/next-icon.svg';
import prevIcon from '../../../assets/images/icons/prev-icon.svg';
import exitIcon from '../../../assets/images/icons/exit-icon.svg';
import warningIcon from '../../../assets/images/icons/warning-icon.svg';
import {
	handleGetQuestionSets,
	handlePracticeSectionsSubmit,
	handleCounterGet,
	handleCounterPost,
	timedSaveAsDraft,
	getTimedSaveAsDraftData
} from '../../../redux/practice';
import { resetFormData } from '../../../redux/practice/slice';
import './styles.scss';

const TimedSectionPage = () => {
	const navigate = useNavigate();
	const shouldRender = useRef(true);
	const shouldSaveDataAsDraft = useRef(true);
	const dispatch = useDispatch();
	const startTime = useRef(null);
	const questionIndex = useRef({ i: null, j: null, k: null });
	const [show, setShow] = useState(false);
	const [questionId, setQuestionId] = useState(0);
	const [id, setId] = useState(null);
	const [isSubmit, setIsSubmit] = useState(false);
	const [loadingForResponse, setLoadingForResponse] = useState(true);
	const [submittedSectionModal, setSubmittedSectionModal] = useState(false);
	const [postCounterCall, setPostCounterCall] = useState(false);
	const [warningShow, setWarningShow] = useState(false);
	const [qList, setQList] = useState([]);

	const qSetId = new URLSearchParams(location.search).get('id');

	const {
		loading,
		questionsList,
		practiceSubmitResponse,
		counterResponse,
		counterPostResponse,
		getTimedSaveAsDraftDataResponse,
		timedSectionLoading
	} = useSelector((state) => state.practice);

	useEffect(() => {
		const beforeunloadCallback = (event) => {
			event.preventDefault();
			event.returnValue = '';
		};
		window.addEventListener('beforeunload', beforeunloadCallback);
		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', function () {
			window.history.pushState(null, document.title, window.location.href);
			values && setShow(true);
		});
		return () => {
			window.removeEventListener('beforeunload', beforeunloadCallback, false);
		};
	}, []);

	useEffect(() => {
		if (shouldRender.current) {
			dispatch(handleCounterGet(qSetId));
			shouldRender.current = false;
			const promise = dispatch(handleGetQuestionSets(qSetId));
			return () => {
				promise.abort();
				dispatch(resetFormData());
			};
		}
	}, []);

	useEffect(() => {
		if (
			counterResponse &&
			counterResponse.data &&
			counterResponse.data.count === 0
		) {
			dispatch(
				handleCounterPost({
					set_id: qSetId,
					count: 1
				})
			);
			setPostCounterCall(true);
		}
		if (
			counterResponse &&
			counterResponse.data &&
			counterResponse.data.count > 0
		) {
			dispatch(getTimedSaveAsDraftData(qSetId));
		}
	}, [counterResponse]);

	useEffect(() => {
		if (
			getTimedSaveAsDraftDataResponse &&
			getTimedSaveAsDraftDataResponse.status_code === 200
		) {
			dispatch(handlePracticeSectionsSubmit(getTimedSaveAsDraftDataResponse.data));
			setSubmittedSectionModal(true);
		}
	}, [getTimedSaveAsDraftDataResponse]);

	useEffect(() => {
		if (postCounterCall) {
			if (counterPostResponse && counterPostResponse.status_code === 200) {
				setPostCounterCall(false);
			} else if (counterPostResponse.error) {
				setPostCounterCall(false);
				toast.error(counterPostResponse.message);
			}
		}
	}, [counterPostResponse]);

	const [isCall, setIsCall] = useState(false);

	useEffect(() => {
		if (isCall) {
			if (practiceSubmitResponse && practiceSubmitResponse.status_code === 200) {
				setIsCall(false);
				navigate('/practice');
			} else if (practiceSubmitResponse && practiceSubmitResponse.error) {
				setIsCall(false);
				toast.error(practiceSubmitResponse.message);
			}
		}
	}, [practiceSubmitResponse]);

	const timeStamp = (hours, minutes) => {
		return (hours * 3600 + minutes * 60) * 1000;
	};

	const onSubmit = (values) => {
		setIsCall(true);
		localStorage.removeItem('time');
		localStorage.removeItem('save_draft');
		const totalTime = Date.now() - startTime.current;
		const { i, j, k } = questionIndex.current;

		const temp = values.list_sections[i].list_ags[j].list_ans[k].list_times;
		const tempListStarts =
			values.list_sections[i].list_ags[j].list_ans[k].list_starts;

		values.list_sections[i].list_ags[j].list_ans[k].list_times = [
			...temp,
			totalTime
		];
		values.list_sections[i].list_ags[j].list_ans[k].list_starts = [
			...tempListStarts,
			moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS')
		];

		setShow(false);
		for (let i = 0; i < values.list_sections.length; i++) {
			const arr = Object.values(values.list_sections[i].dict_qno_attempted);
			values.list_sections[i].noq_attempted = 0;
			values.list_sections[i].noq_total = arr.length;
			for (let j = 0; j < arr.length; j++) {
				if (arr[j] === true) {
					values.list_sections[i].noq_attempted += 1;
				}
			}
		}
		if (isSubmit) {
			values.clicked_submit = true;
		} else values.clicked_submit = false;
		dispatch(handlePracticeSectionsSubmit(values));
	};

	const onSaveAsDraft = () => {
		const totalTime = Date.now() - startTime.current;
		startTime.current = Date.now();
		const { i, j, k } = questionIndex.current;
		const valuesForDraft = values;

		const temp =
			valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_times;
		const tempListStarts =
			valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_starts;

		valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_times = [
			...temp,
			totalTime
		];
		valuesForDraft.list_sections[i].list_ags[j].list_ans[k].list_starts = [
			...tempListStarts,
			moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS')
		];

		for (let i = 0; i < valuesForDraft.list_sections.length; i++) {
			const arr = Object.values(
				valuesForDraft.list_sections[i].dict_qno_attempted
			);
			valuesForDraft.list_sections[i].noq_attempted = 0;
			valuesForDraft.list_sections[i].noq_total = arr.length;
			for (let j = 0; j < arr.length; j++) {
				if (arr[j] === true) {
					valuesForDraft.list_sections[i].noq_attempted += 1;
				}
			}
		}
		valuesForDraft.clicked_submit = false;
		setValues(valuesForDraft);
		dispatch(timedSaveAsDraft(valuesForDraft));
	};

	const { values, handleChange, handleSubmit, setFieldValue, setValues } =
		useFormik({
			enableReinitialize: true,
			initialValues: {},
			onSubmit
		});

	useEffect(() => {
		if (isEmpty(questionsList)) return;
		startTime.current = Date.now();
		if (!localStorage.getItem('time') && questionsList) {
			localStorage.setItem(
				'time',
				Date.now() + timeStamp(questionsList.time_hrs, questionsList.time_mins)
			);
		}

		if (questionsList && questionsList.error && isEmpty(questionsList)) {
			setLoadingForResponse(false);
		}

		const temp = questionsList.list_sections
			.map((e) => {
				return e.list_qgs.map((element) => {
					return element.list_ques;
				});
			})
			.flat()
			.flat();

		setQList(temp);

		setFieldValue('qset_id', `${questionsList.set_id}`);
		setFieldValue('clicked_submit', null);

		for (let i = 0; i < questionsList.list_sections.length; i++) {
			const noqAttempted = questionsList.list_sections[i].noq_attempted;
			const noqTotal = questionsList.list_sections[i].noq_total;

			setFieldValue(`list_sections.${i}.noq_attempted`, 0);

			if (noqAttempted) {
				setFieldValue(`list_sections.${i}.noq_attempted`, noqAttempted);
			}
			if (noqTotal) {
				setFieldValue(`list_sections.${i}.noq_total`, noqTotal);
			}

			for (let j = 0; j < questionsList.list_sections[i].list_qgs.length; j++) {
				for (
					let k = 0;
					k < questionsList.list_sections[i].list_qgs[j].list_ques.length;
					k++
				) {
					const qno = questionsList.list_sections[i].list_qgs[j].list_ques[k].qno;
					const dictQnoAttempted = questionsList.list_sections[i].dict_qno_attempted;
					const listQues = questionsList.list_sections[i].list_qgs[j].list_ques[k];

					setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, false);

					// for questions after save as draft and submit
					if (
						(dictQnoAttempted && dictQnoAttempted[qno]) ||
						(has(listQues, 'attempted_obj') && !isNull(listQues.attempted_obj))
					) {
						setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, true);
					}

					setFieldValue(`list_sections.${i}.list_ags.${j}.list_ans.${k}.qno`, qno);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
						null
					);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.list_times`,
						[]
					);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.list_starts`,
						[]
					);

					// for submitted Question
					if (has(listQues, 'attempted_obj')) {
						setFieldValue(
							`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
							listQues.attempted_obj
						);
					}
					// for Questions after save as a draft
					if (
						questionsList &&
						questionsList.draft &&
						questionsList.draft.attempted
					) {
						setFieldValue(
							`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
							listQues.draft.stud_ans
						);
					}
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.q_type`,
						listQues.q_type
					);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.q_id`,
						listQues.q_id
					);
				}
			}
		}
		setLoadingForResponse(false);
		return () => {
			localStorage.removeItem('time');
		};
	}, [questionsList]);

	const onEnd = () => {
		setId(null);
	};
	const { speak } = useSpeechSynthesis({ onEnd });

	const handleClick = (data, questionId) => {
		if (isEmpty(questionsList)) return;
		const { q_type: qType } = data;
		const { i, j, k } = getQuestionIndex(questionsList, data);
		questionIndex.current = { i, j, k };
		const props = {};
		const changeClass = () => {
			speak({ text: data.q_html });
			setId(k);
		};
		const active = () => {
			if (id === k) {
				return true;
			}
			return false;
		};

		props.key = k;
		props.active = active();
		props.onPress = changeClass;
		props.data = data;
		props.indexValue = { i, j, k };
		props.value = values;
		props.onChange = handleChange;
		props.setFieldVal = setFieldValue;
		props.isTimedQuestion = true;
		props.questionId = questionId + 1;
		props.fieldValue = getFieldVal(values, i, j, k);
		props.name = `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`;

		if (isEmpty(values)) return;

		if (
			shouldSaveDataAsDraft.current &&
			counterResponse &&
			counterResponse.data &&
			counterResponse.data.count === 0
		) {
			shouldSaveDataAsDraft.current = false;
			dispatch(timedSaveAsDraft(values));
		}

		switch (qType) {
			case 'TextAnswerQ':
				return <TextAnswerQ {...props} />;
			case 'DictationQ':
				return <DictationQ {...props} />;
			case 'MathExpressionQ':
				return <MathExpressionQ {...props} />;
			case 'MultipleChoiceQ':
				return <MultipleChoiceQ {...props} />;
			case 'UniqueChoiceQ':
				return <UniqueChoiceQ {...props} />;
			case 'TrueFalseQ':
				return <TrueFalseQ {...props} />;
			case 'FillImgQ':
				return <FillImgQ {...props} />;
			case 'FillBlankQ':
				return <FillBlankQ {...props} />;
			case 'FillGroupQ':
				return <FillGroupQ {...props} />;
			case 'FillInlineQ':
				return <FillInlineQ {...props} />;
			case 'MatchItemsQ':
				return <MatchItemsQ {...props} />;
			default:
				return '';
		}
	};

	const handleAttempted = (e) => {
		if (e && e.ans_obj && e.ans_obj.correctness === 'right') {
			return 'right';
		} else if (e && e.ans_obj && e.ans_obj.correctness === 'wrong') {
			return 'wrong';
		} else if (
			e &&
			e.attempted_obj &&
			e.ans_obj &&
			isArray(e.ans_obj.ugot_right)
		) {
			if (
				e &&
				e.ans_obj &&
				e.ans_obj.ugot_right &&
				e.ans_obj.ugot_right.every((e) => e === true)
			) {
				return 'right';
			} else {
				return 'wrong';
			}
		} else if (
			!isEmpty(values) &&
			values.list_sections[getQuestionNumber(values, e)].dict_qno_attempted[
				e.qno
			] === true
		) {
			return 'question_attempted';
		} else {
			return '';
		}
	};

	const handleSubmittedTimedSection = () => {
		navigate('/practice');
	};

	const modalContentArray = [
		'You already have submitted.',
		'Now you cannot resubmit.'
	];
	const modalContent = modalContentArray.map((e, i) => <p key={i}>{e}</p>);
	const modalFooterButtons = [
		{
			label: 'Ok',
			variant: 'danger',
			onClick: handleSubmittedTimedSection
		}
	];

	const footerButtons = [
		{
			label: 'Cancel',
			variant: 'danger',
			onClick: () => setShow(false)
		},
		{
			label: isSubmit ? 'Submit' : 'Exit',
			variant: 'primary',
			onClick: handleSubmit
		}
	];

	if (loading || loadingForResponse) {
		return <div className='loader_section'>Loading...</div>;
	}

	if (questionsList && questionsList.error) {
		return <ErrorHandleComponent errorMessage={questionsList.message} />;
	}

	if (isEmpty(qList)) return;

	return (
		<div className='timed_section'>
			{timedSectionLoading && (
				<div className='timed_section_loader'>
					<Loader />
				</div>
			)}
			<div className='questions_wrapper'>
				<div className='question_section_wrapper'>
					<section className='timer_section'>
						<Container>
							<div className='timer_wrapper'>
								<div className='timer_left_buttons'>
									<Button
										onClick={() => {
											setShow(true);
											setIsSubmit(false);
										}}
										className='exit_btn'
									>
										<ReactSVG src={exitIcon} className='arrow_icon' />
									</Button>
									<Button
										onClick={() => {
											setWarningShow(true);
										}}
										className='warning_btn'
									>
										<ReactSVG src={warningIcon} className='warning_icon' />
									</Button>
								</div>
								<Timer handleSubmit={handleSubmit} />
								<Button
									onClick={() => {
										setIsSubmit(true);
										setShow(true);
									}}
									className='grad_submit_btn'
								>
								Submit for grading
								</Button>
							</div>
						</Container>
					</section>
					<section className='question_section'>
						<Container>
							<div className='questions_wrap'>
								<Container id={questionId + 1}>
									{handleClick(qList[questionId], questionId)}
								</Container>
							</div>
						</Container>
					</section>
				</div>
				<div className='questions_list_box'>
					<Container>
						<div className='question_list_section'>
							<div className='question_list_section_wrapper'>
								{qList.map((e, i) => {
									return (
										<div
											className={`dots ${handleAttempted(e)} ${
												i === questionId ? 'current_que' : ''
											}`}
											key={i}
											onClick={() => {
												setQuestionId(i);
												onSaveAsDraft();
											}}
										>
											{i + 1}
										</div>
									);
								})}
							</div>
							<Button
								disabled={questionId === 0}
								onClick={() => {
									setQuestionId(questionId - 1);
									startTime.current = Date.now();
								}}
								className='arrow_btn prev'
							>
								<ReactSVG src={prevIcon} className='arrow_icon' /> Previous
							</Button>
							<Button
								onClick={() => {
									setQuestionId(questionId + 1);
									onSaveAsDraft();
								}}
								className='arrow_btn next'
								disabled={questionId === qList.length - 1}
							>
							Next <ReactSVG src={nextIcon} className='arrow_icon' />
							</Button>
						</div>
					</Container>
				</div>
				<CustomModal
					title={
						isSubmit
							? 'Are you sure you want to submit?'
							: 'Are you sure you want to exit?'
					}
					content={<p>You wont be able to attempt this question set again.</p>}
					footerButtons={footerButtons}
					show={show}
					handleClose={() => setShow(false)}
				/>
				<CustomModal
					content={modalContent}
					footerButtons={modalFooterButtons}
					show={submittedSectionModal}
					handleClose={handleSubmittedTimedSection}
				/>
				<CustomModal
					title='Warning'
					content={<p>Do not press the back button, refresh the page, or
						close the window during the exam. Doing so will automatically submit the
						exam, and you will not be able to resubmit it. Please ensure that you
						complete the exam without performing any of these actions to avoid
						unintended submission.</p>}
					show={warningShow}
					handleClose={() => setWarningShow(false)}
				/>
			</div>
		</div>
	);
};

export default TimedSectionPage;
