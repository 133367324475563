import Axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getPracticeList,
	getQuestionSections,
	practiceSectionsSubmit,
	importPractice,
	importWebPractice,
	handleGetRequest,
	handlePostRequest
} from '../../services/practice';

export const handleGetPracticeList = createAsyncThunk(
	'handleGetPracticeList',
	async (params, { rejectWithValue }) => {
		const response = await getPracticeList(params);
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleGetQuestionSets = createAsyncThunk(
	'handleGetQuestionSets',
	async (params, { rejectWithValue, signal }) => {
		const source = Axios.CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});
		const response = await getQuestionSections(params, source);
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handlePracticeSectionsSubmit = createAsyncThunk(
	'handlePracticeSectionsSubmit',
	async (params, { rejectWithValue }) => {
		const response = await practiceSectionsSubmit(params);
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleImportPractice = createAsyncThunk(
	'handleImportPractice',
	async (_, { rejectWithValue }) => {
		const response = await importPractice();
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleWebImportPractice = createAsyncThunk(
	'handleWebImportPractice',
	async (_, { rejectWithValue }) => {
		const response = await importWebPractice();
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleTimedSetSubmission = createAsyncThunk(
	'handleTimedSetSubmission',
	async (_, { rejectWithValue }) => {
		const response = await handleGetRequest('admin/practice/web/save/timed-questions');
		if (!response) return rejectWithValue();
		return { response };
	}
);

// Timed Section Apis
export const handleCounterGet = createAsyncThunk(
	'handleCounterGet',
	async (params, { rejectWithValue }) => {
		const response = await handleGetRequest(`/admin/practice/get/timed-question-view-count?set_id=${params}`);
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleCounterPost = createAsyncThunk(
	'handleCounterPost',
	async (params, { rejectWithValue }) => {
		const response = await handlePostRequest(params, '/admin/practice/set/timed-question-view-count');
		if (!response) return rejectWithValue();
		return { response };
	}
);
export const timedSaveAsDraft = createAsyncThunk(
	'timedSaveAsDraft',
	async (params, { rejectWithValue }) => {
		const response = await handlePostRequest(params, '/admin/practice/timed-questions/save-draft');
		if (!response) return rejectWithValue();
		return { response };
	}
);
export const getTimedSaveAsDraftData = createAsyncThunk(
	'getTimedSaveAsDraftData',
	async (params, { rejectWithValue }) => {
		const response = await handleGetRequest(`/admin/practice/get/timed-questions/save-draft?set_id=${params}`);
		if (!response) return rejectWithValue();
		return { response };
	}
);
