import axios from './axios';

// Gives authToken
const token = () => localStorage.getItem('userToken');

// Provides Headers
const headers = () => {
	return {
		headers: {
			Authorization: `Bearer ${token()}`
		}
	};
};

// Get practice list
export const getPracticeList = async () => {
	return await axios
		.get('/admin/get/practice/sets', headers())
		.then((res) => res)
		.catch((err) => err);
};

// Get question sections
export const getQuestionSections = async (sectionId, source) => {
	return await axios
		.get(`/admin/get/set/all-question?set_id=${sectionId}`, {
			cancelToken: source.token,
			headers: { Authorization: `Bearer ${token()}` }
		})
		.then((res) => res)
		.catch((err) => err);
};

// Submit practice section
export const practiceSectionsSubmit = async (params) => {
	return await axios
		.post('/admin/practice/submit', params, headers())
		.then((res) => res)
		.catch((err) => err);
};

export const importPractice = async () => {
	return await axios
		.get('admin/practice/s3_import_practice', headers())
		.then((res) => res)
		.catch((err) => err);
};

export const importWebPractice = async () => {
	return await axios
		.get('admin/practice/web/s3_import_practice', headers())
		.then((res) => res)
		.catch((err) => err);
};

export const handleGetRequest = async (path) => {
	return await axios
		.get(path, headers())
		.then((res) => res)
		.catch((err) => err);
};

export const handlePostRequest = async (params, path) => {
	return await axios
		.post(path, params, headers())
		.then((res) => res)
		.catch((err) => err);
};
