import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { isEmpty, isNull } from 'lodash';
import QuestionHeadComponent from '../QuestionHeadComponent';
import QuestionScoreComponent from '../QuestionScoreComponent';
import {
	attemptedQuestionCounterType4,
	questionCorrectnessType1,
	questionCorrectnessType2
} from '../../../utils/practice';
import Questionmarksvg from '../../../assets/images/icons/question-mark.svg';
import './styles.scss';

const FillImgQ = ({
	data,
	value,
	indexValue,
	setFieldVal,
	fieldValue,
	name
}) => {
	const [questions, setQuestions] = useState([]);
	const answerVal = () => {
		if (isNull(fieldValue)) {
			return '';
		}
		return data.list_cimg[fieldValue];
	};
	const [answer, setAnswer] = useState(answerVal());

	useEffect(() => {
		setQuestions(
			data.list_qimg.map((e) => {
				return e;
			})
		);
	}, []);

	useEffect(() => {
		attemptedQuestionCounterType4(data, value, indexValue, fieldValue);
	}, [value]);

	const handleChange = (e, index) => {
		if (isEmpty(answer) || e.target.value !== answer) {
			setFieldVal(name, index);
			setAnswer(e.target.value);
		} else {
			setFieldVal(name, null);
			setAnswer('');
		}
	};

	const handleAnsSvg = (element) => {
		if (!isEmpty(element)) { return <div dangerouslySetInnerHTML={{ __html: element }}></div>; }
		if (!isEmpty(answer)) { return <div dangerouslySetInnerHTML={{ __html: answer }}></div>; }
		return <ReactSVG src={Questionmarksvg} className="que_svg" />;
	};

	return (
		<div className='fib_base_display' id={`${data.q_type}_${data.qno}`}>
			<div className='question_number'>
				<h6>{data.qno}</h6>
				<QuestionScoreComponent data={data} />
			</div>
			<div className='fib_base_display_text'>
				<QuestionHeadComponent
					data={data}
					questionData={data.q_html}
					questionHintData={data.hints}
					questionType={data.q_type}
					headText={data.q_html}
				/>
				<div className='fib_base_display_ques'>
					{questions &&
						questions.map((element, index) => {
							return (
								<div
									key={index}
									className={`${
										index === 3
											? `fib_base_display_que_svg ${
												data.ans_obj && questionCorrectnessType1(data)
											}`
											: 'fib_base_display_svg'
									}`}
								>
									{handleAnsSvg(element, index)}
								</div>
							);
						})}
				</div>
				<div className='fib_base_display_radio'>
					{data.list_cimg &&
						data.list_cimg.map((element, index) => {
							return (
								<Form.Check key={index}>
									<Form.Check.Input
										key={index}
										onChange={(e) => handleChange(e, index)}
										checked={false}
										value={element}
										type='radio'
										name={name}
										disabled={data.ans_obj}
									/>
									<Form.Check.Label
										className={`${answer === element && 'active'} ${
											data.ans_obj && questionCorrectnessType2(data, index)
										}`}
									>
										<div dangerouslySetInnerHTML={{ __html: element }}></div>
									</Form.Check.Label>
								</Form.Check>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default FillImgQ;
