/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull, debounce } from "lodash";
import { Form, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader";
import {
  deleteResolvedFlagQuestions,
  getFlagQuestion,
  getFlaggedQuestions,
  resolveFlaggedQuestion,
} from "../../../redux/admin";
import LineChartIcon from "../../../assets/images/chart.png";
import "./styles.scss";
import { MdOutlineEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import ResolveFlaggedModal from "./ResolveFlag";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CustomModal from "../CustomModal";

const FlaggedQuestions = () => {
  const [sort, setSort] = useState(-1);
  const [sortField, setSortField] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("pending");
  const [page, setPage] = useState(1);
  const [resolveFlagModal, setResolveFlagModal] = useState([false, null]);
  const [flagParams, setFlagParams] = useState({});
  const [currentFlag, setCurrentFlag] = useState(0);
  const [filterHover, setFilterHover] = useState("");
  const [confirmResolve, setConfirmResolve] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const { loadingForFlagList, flaggedQuestionsData } = useSelector(
    (state) => state.admin
  );
  const handleFlaggedQuestions = () => {
    const params = {
      page: page,
      limit,
      search: searchInput,
      sort: sortField,
      orderBy: sort,
      filter_by: filter,
    };

    dispatch(getFlaggedQuestions(params));
  };

  useEffect(() => {
    handleFlaggedQuestions();
  }, [page, sort, sortField, searchInput, filter]);

  const handleResolveDeny = (params) => {
    dispatch(
      getFlagQuestion({
        _id: params?._id,
        q_id: params.q_id,
        resolved: params?.resolvedQue,
      })
    );
    const data = {
      _id: params._id,
      flag_id: params.flag_id,
      q_id: params.q_id,
      resolution_comment: "",
    };
    setFlagParams(data);
    setResolveFlagModal([true, params.q]);
  };
  const columns = [
    {
      id: "id",
      name: "SN",
      width: 50,
      selector: (row) => row.id,
      sortable: false,
      sortField: "id",
      className: "cs-column-1",
    },
    {
      id: "q_id",
      name: "Question Id",
      minWidth: 150,
      selector: (row) => row.q_id,
      sortable: true,
      sortField: "q_id",
    },
    {
      id: "flag_count",
      name: "No of times",
      minWidth: 150,
      selector: (row) => row.flags_count,
      sortable: true,
      sortField: "flags_count",
      cell: (params) => {
        // const usernames = params?.unique_usernames?.length
        //   ? params?.unique_usernames?.join(", ")
        //   : "";
        return (
          // <OverlayTrigger
          //   placement="top"
          //   overlay={<Tooltip id="tooltip">{usernames}</Tooltip>}
          // >
          <p className="mt-3">{params?.flags_count}</p>
          // </OverlayTrigger>
        );
      },
    },
    {
      id: "flag_timestamp",
      name: "First Flag Timestamp",
      minWidth: 150,
      selector: (row) => row.flag_timestamp,
      sortable: true,
      sortField: "flag_timestamp",
    },

    {
      id: "last_flag_timestamp",
      name: "Last Flag Timestamp",
      minWidth: 150,
      selector: (row) => row.last_flag_timestamp,
      sortable: true,
      sortField: "last_flag_timestamp",
    },
    {
      id: "actions",
      name: "Actions",
      minWidth: 110,
      selector: (row) => row.flag_timestamp,
      sortable: false,
      sortField: "actions",
      cell: (params) => {
        return (
          <Button
            variant="success"
            style={{ backgroundColor: "green", color: "white" }}
            className="text-nowrap"
            onClick={() => handleResolveDeny(params, "resolved")}
          >
            View
          </Button>
        );
      },
    },

    {
      id: "status",
      name: "Status",
      minWidth: 110,
      selector: (row) => row.resolvedQue,
      sortable: false,
      sortField: "status",
      cell: (params) => {
        const statusStyle = {
          color: params?.resolvedQue ? "grey" : "blue",
        };
        return (
          <span style={statusStyle}>
            {params?.resolvedQue ? "Resolved" : "Pending"}
          </span>
        );
      },
    },
  ];
  function createData(
    id,
    q,
    _id,
    flag_id,
    q_id,
    username,
    flag,
    comment,
    flag_timestamp,
    resolved,
    unique_usernames,
    flags_count,
    last_flag_timestamp,
    resolvedQue
  ) {
    return {
      id,
      q,
      _id,
      flag_id,
      q_id,
      username,
      flag,
      comment,
      flag_timestamp,
      resolved,
      unique_usernames,
      flags_count,
      last_flag_timestamp,
      resolvedQue,
    };
  }

  const rowsData = [
    flaggedQuestionsData?.data &&
      flaggedQuestionsData?.data?.map((item, index) => {
        const flag = item?.flags?.flag?.mistake_in_the_correction
          ? "mistake in correction"
          : item?.flags?.flag?.mistake_in_the_question
          ? "mistake in question"
          : "";
        return createData(
          index + 1,
          item?.q,
          item?._id,
          item?.flags?._id,
          item?.q_id,
          item?.flags?.username,
          flag,
          item?.flags?.flag?.comment,
          moment(item?.flags?.flag_timestamp).format("DD MMM YYYY HH:mm:ss"),
          item?.flags?.resolved,
          item?.unique_usernames,
          item?.flags_count,
          moment(item?.last_flag?.flag_timestamp).format(
            "DD MMM YYYY HH:mm:ss"
          ),
          item?.resolved
        );
      }),
  ];

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSortModelChange = (column, sortDirection) => {
    const orderBy = sortDirection === "asc" ? 1 : -1;
    setSort(orderBy);
    setSortField(column.sortField);
  };
  // Search
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchInput(value);
  };

  const onSearchDebounce = debounce(handleSearch, 1500);

  const handleFlaggedList = (value) => {
    setSort(-1);
    setFilter(value);
  };

  const handleDeleteResolvedFlagList = () => {
    dispatch(deleteResolvedFlagQuestions(true))
      .unwrap()
      .then((res) => {
        if (res?.response?.status === 200) {
          toast.success(res?.response?.data?.message);
          handleFlaggedQuestions();
          setShowConfirmModal(false);
        } else if (res?.response?.status === 201) {
          toast.error(res?.response?.data?.message);
          setShowConfirmModal(false);
        } else {
          toast.success("Some thing went wrong!");
          setShowConfirmModal(false);
        }
      });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      !loadingForFlagList && (
        <>
          <h5 className="me-auto">Flagged Questions</h5>
          <Button
            style={{
              boxShadow:
                filterHover === "delete-resolved"
                  ? "0 0 0 0rem rgba(246, 150, 104, 0.5)"
                  : "",
            }}
            className={`custom-button-delete-tab btn ${
              filterHover === "delete-resolved"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onMouseEnter={() => setFilterHover("delete-resolved")}
            onMouseLeave={() => setFilterHover("")}
            onClick={() => setShowConfirmModal(true)}
          >
            Delete Resolved
          </Button>
          <Button
            style={{
              boxShadow:
                filter === "pending"
                  ? "0 0 0 0rem rgba(246, 150, 104, 0.5)"
                  : "",
            }}
            className={`custom-button-tab btn ${
              filter === "pending"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => handleFlaggedList("pending")}
          >
            Show Pending
          </Button>
          <Button
            style={{
              boxShadow:
                filter === "resolved"
                  ? "0 0 0 0rem rgba(246, 150, 104, 0.5)"
                  : "",
            }}
            className={`btn custom-button-tab ${
              filter === "resolved"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => handleFlaggedList("resolved")}
          >
            Show Resolved
          </Button>
          <Button
            style={{
              boxShadow:
                filter === "all" ? "0 0 0 0rem rgba(246, 150, 104, 0.5)" : "",
            }}
            className={`btn custom-button-tab ${
              filter === "all"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => handleFlaggedList("all")}
          >
            Show All
          </Button>
        </>
      )
    );
  }, [searchInput, filter, loadingForFlagList]);

  if (isNull(rowsData[0])) return;

  const showConfirmResolveModal = () => {
    setConfirmResolve(true);
  };

  const resolveFlagQuestion = () => {
    const flag_params = { ...flagParams, resolved_timestamp: new Date() };
    dispatch(resolveFlaggedQuestion(flag_params))
      .unwrap()
      .then((res) => {
        if (res?.response?.status === 200) {
          toast.success(res?.response?.data?.message);
          handleFlaggedQuestions();
          setResolveFlagModal(false);
        }
        setConfirmResolve(false);
      });
  };

  const handleCloseFlagModal = () => {
    setResolveFlagModal([false, null]);
    setCurrentFlag(0);
  };
  const modalTitle = "Resolve Flagged question";
  const modalContent = <p>Add some test to resolve flag</p>;
  const modalFooterButtons = [
    { label: "Cancel", variant: "primary", onClick: handleCloseFlagModal },
    {
      label: "Mark as resolved",
      variant: "primary",
      onClick: showConfirmResolveModal,
    },
  ];

  const handleChangeComment = (e) => {
    setFlagParams((prev) => ({ ...prev, resolution_comment: e.target.value }));
  };

  const handleCloseCustomModal = () => {
    setShowConfirmModal(false);
  };
  const modalTitle2 = "Confirm to delete";
  const modalContent2 = <p>Are you want to delete all flag questions!</p>;
  const modalFooterButtons2 = [
    { label: "Cancel", variant: "primary", onClick: handleCloseCustomModal },
    {
      label: "Confirm",
      variant: "primary",
      onClick: handleDeleteResolvedFlagList,
    },
  ];

  return (
    <div className="dashboard_wrapper">
      <Header className="pe-0" />
      <Container
        className="pt-5 fixing-loader inner-speacoal-off1"
        style={{ minHeight: "calc(100vh - 141px)" }}
      >
        <div
          style={{ width: "100%" }}
          className="d-flex flex-wrap userlist_wrapper"
        >
          <DataTable
            columns={columns}
            data={rowsData[0]}
            progressPending={loadingForFlagList}
            progressComponent={<Loader />}
            highlightOnHover
            pagination
            paginationServer
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationTotalRows={flaggedQuestionsData?.total_count}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onSort={handleSortModelChange}
            sortServer
          />
        </div>

        {!loadingForFlagList ? (
          <ResolveFlaggedModal
            size={"lg"}
            title={modalTitle}
            content={modalContent}
            footerButtons={modalFooterButtons}
            show={resolveFlagModal}
            handleClose={handleCloseFlagModal}
            flagParams={flagParams}
            handleChangeComment={handleChangeComment}
            currentFlag={currentFlag}
            setCurrentFlag={setCurrentFlag}
            confirmResolve={confirmResolve}
            setConfirmResolve={setConfirmResolve}
            resolveFlagQuestion={resolveFlagQuestion}
          />
        ) : null}

        <CustomModal
          size={"md"}
          title={modalTitle2}
          content={modalContent2}
          footerButtons={modalFooterButtons2}
          show={showConfirmModal}
          handleClose={handleCloseCustomModal}
        />
      </Container>
      <div className="footer">
        <h5 className="text-center">
          &copy; {new Date().getFullYear()} boo9 inc.
        </h5>
      </div>
    </div>
  );
};

export default FlaggedQuestions;
