/* eslint-disable */
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Row,
	Col,
	Button,
	Form as BootstrapForm
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleSignup } from '../../../redux/auth';

const SignUp = () => {
	const dispatch = useDispatch();

	const [hideShowValue, setValues] = useState({
		password: '',
		showPassword: false
	});
	const handleClickShowPassword = () => {
		setValues({
			...hideShowValue,
			showPassword: !hideShowValue.showPassword
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Formik and YUP validation
	const initialValues = {
		username: '',
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		// age_conformation:true
	};
	// const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
	// 	initialValues,
	// 	validationSchema: Yup.object({
	// 		username: Yup.string()
	// 			.required('Username Required'),
	// 		first_name: Yup.string()
	// 			.required('first_name Required'),
	// 		last_name: Yup.string()
	// 			.required('last_name Required'),
	// 		password: Yup.string()
	// 			.min(5, 'At least 5 characters required')
	// 			.max(10, 'Must be 10 characters or less')
	// 			.required('password Required'),
	// 		email: Yup.string()
	// 			.email('Invalid email address')
	// 			.required('email Required')
	// 	}),
	// 	onSubmit: values => {
	// 		dispatch(handleSignup({ body: values }));
	// 	}
	// });
	const boxStyle = {
		maxWidth: '380px',
		margin: '0 auto',
		padding: '1rem'
	};
	return (
		<div className='login_wrapper'>
			<Container className='mw-600' as='main'>
				<Row>
					<Col className='text-center'>
						<h2 className='signup_heading'>SignUp</h2>
					</Col>
				</Row>
				<div style={boxStyle}>
					<Formik
						initialValues={initialValues}
						validationSchema={Yup.object({
							username: Yup.string().required('Username Required'),
							first_name: Yup.string().required('first_name Required'),
							last_name: Yup.string().required('last_name Required'),
							password: Yup.string()
								.min(5, 'At least 5 characters required')
								.max(10, 'Must be 10 characters or less')
								.required('password Required'),
							email: Yup.string()
								.email('Invalid email address')
								.required('email Required')
						})}
						onSubmit={(values) => {
							dispatch(handleSignup({ body: values }));
						}}
					>
						{({ values, errors, touched, handleSubmit }) => {
							return (
								<Form>
									<BootstrapForm.Group
										as={Col}
										controlId='username'
										className={`position-relative ${
											values.username.length > 0 && 'data_added'
										} ${touched.username && errors.username && 'field_error'}`}
									>
										<Field as={BootstrapForm.Control} type='text' name='username' />
										<BootstrapForm.Label>Username *</BootstrapForm.Label>
										<ErrorMessage
											name='username'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='first_name'
										className={`position-relative ${
											values.first_name.length > 0 && 'data_added'
										} ${touched.first_name && errors.first_name && 'field_error'}`}
									>
										<Field as={BootstrapForm.Control} type='text' name='first_name' />
										<BootstrapForm.Label>Firstname *</BootstrapForm.Label>
										<ErrorMessage
											name='first_name'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='last_name'
										className={`position-relative ${
											values.last_name.length > 0 && 'data_added'
										} ${touched.last_name && errors.last_name && 'field_error'}`}
									>
										<Field as={BootstrapForm.Control} type='text' name='last_name' />
										<BootstrapForm.Label>Lastname *</BootstrapForm.Label>
										<ErrorMessage
											name='last_name'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='email'
										className={`position-relative ${
											values.email.length > 0 && 'data_added'
										} ${touched.email && errors.email && 'field_error'}`}
									>
										<Field as={BootstrapForm.Control} type='email' name='email' />
										<BootstrapForm.Label>Email *</BootstrapForm.Label>
										<ErrorMessage
											name='email'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='password'
										className={`position-relative ${
											values.password.length > 0 && 'data_added'
										} ${touched.password && errors.password && 'field_error'}`}
									>
										<Field
											as={BootstrapForm.Control}
											type={hideShowValue.showPassword ? 'text' : 'password'}
											name='password'
										/>
										<Button
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											className='show_btn'
										>
											{hideShowValue.showPassword ? 'hide' : 'show'}
										</Button>
										<BootstrapForm.Label>Password *</BootstrapForm.Label>
										<ErrorMessage
											name='password'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<Button variant='contained' onClick={handleSubmit} type='submit'>
										Signup
									</Button>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Container>
		</div>
	);
};

export default SignUp;
