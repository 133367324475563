import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetUserScore } from '../../../redux/admin';
import ChartComponent from '../../../components/ChartComponent';
import { Container } from 'react-bootstrap';
import './style.scss';
import Header from '../../../components/Header';

const UserDashBoard = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { userScore, loading } = useSelector((state) => state.admin);

	const userId = pathname.split('/').pop();

	useEffect(() => {
		dispatch(handleGetUserScore(userId));
	}, []);

	if (loading) {
		return <div className='loader_section'>Loading...</div>;
	}

	if (userScore && userScore.error) {
		return (
			<div className='loader_section error'>
				<p>
					{userScore.message.user_id
						? userScore.message.user_id[0]
						: userScore.message || 'Please try after some time'}
				</p>
			</div>
		);
	}

	return (
		<div className='profile_page'>
			<div className="dashboard_wrapper">
				<Header />
				<div className='history_wrapper'>
					<Container>
						<h2>Score History</h2>
						<div className='history_chart'>
							<ChartComponent userLastWeekScore={userScore} loading={loading} />
						</div>
					</Container>
				</div>
			</div>
		</div>
	);
};

export default UserDashBoard;
