import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import QuestionHeadComponent from '../QuestionHeadComponent';
import RightAnswereComponent from '../RightAnswereComponent';
import QuestionScoreComponent from '../QuestionScoreComponent';
import {
	questionCorrectnessType1,
	attemptedQuestionCounterType1,
	correctQuestionVal,
	handleChange
} from '../../../utils/practice';

import './styles.scss';

const MathExpressionQ = ({
	data,
	value,
	indexValue,
	fieldValue,
	name,
	setFieldVal
}) => {
	useEffect(() => {
		attemptedQuestionCounterType1(data, value, indexValue, fieldValue);
		correctQuestionVal(fieldValue, setFieldVal, name);
	}, [value]);

	return (
		<div className='mxq_display_componet' id={`${data.q_type}_${data.qno}`}>
			<div className='question_number'>
				<h6>{data.qno}</h6>
				<QuestionScoreComponent data={data} />
			</div>
			<div className='mxq_display_componet_text'>
				<QuestionHeadComponent
					data={data}
					questionData={data.q_text}
					questionHintData={data.hints}
					headText={data.q_html}
					questionType={data.q_type}
				/>
				<div className='input-tooltip'>
					<Form.Control
						type='text'
						name={name}
						onChange={(e) => {
							handleChange(e, setFieldVal, name);
						}}
						value={fieldValue || ''}
						disabled={data.ans_obj}
						className={data.ans_obj && questionCorrectnessType1(data)}
					/>
					<RightAnswereComponent data={data} />
				</div>
			</div>
		</div>
	);
};

export default MathExpressionQ;
