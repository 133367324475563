/* eslint-disable */
import React, { useState, memo } from "react";
import { ReactSVG } from "react-svg";
import { isEmpty } from "lodash";
import explain from "../../../assets/images/icons/explain.svg";
import CustomModal from "../../CustomModal";
import Markdown from "../../Markdown";
import "./styles.scss";

const QueExplanationComponent = ({ data, explanations }) => {
  const [explanationsModal, setExplanationsModal] = useState(false);

  const explanationsCloseModal = () => setExplanationsModal(false);
  const explanationsOpenModal = () => setExplanationsModal(true);

  if (isEmpty(explanations) || !data?.ans_obj) return;

  const modalTitle = "Explanations";
  const modalContent = (
    <ol>
      {explanations.map((e, i) => {
        if (explanations[i].e_type === "lod__worpinfo") {
          return (
            <li key={i}>
              <Markdown>{e.spec[0].meaninge}</Markdown>
            </li>
          );
        } else {
          return (
            <li key={i}>
              {" "}
              <Markdown>{e}</Markdown>{" "}
            </li>
          );
        }
      })}
    </ol>
  );
  const modalFooterButtons = [
    { label: "Close", variant: "danger", onClick: explanationsCloseModal },
  ];

  return (
    <>
      <ReactSVG
        src={explain}
        className="info_icon blub"
        onClick={explanationsOpenModal}
      />
      <CustomModal
        title={modalTitle}
        content={modalContent}
        footerButtons={modalFooterButtons}
        show={explanationsModal}
        handleClose={explanationsCloseModal}
      />
    </>
  );
};

export default memo(QueExplanationComponent);
