import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Form as BootstrapForm,
	Alert,
	Button
} from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangePassword } from '../../../redux/auth';

const ChangePassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { changePasswordResponse } = useSelector((state) => state.auth);

	const [hideShowValue, setValues] = useState({
		current_password: '',
		showPassword: false
	});

	const [hideShowNewValue, setNewValues] = useState({
		current_password: '',
		showPassword: false
	});

	const [hideShowConfirmValue, setConfirmValues] = useState({
		current_password: '',
		showPassword: false
	});

	const handleClickShowPassword = () => {
		setValues({
			...hideShowValue,
			showPassword: !hideShowValue.showPassword
		});
	};

	const handleClickShowNewPassword = () => {
		setNewValues({
			...hideShowNewValue,
			showPassword: !hideShowNewValue.showPassword
		});
	};

	const handleClickShowConfirmPassword = () => {
		setConfirmValues({
			...hideShowConfirmValue,
			showPassword: !hideShowConfirmValue.showPassword
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Formik and YUP validation
	const initialValues = {
		current_password: '',
		password: '',
		confirm_password: ''
	};

	// const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
	// 	useFormik({
	// 		initialValues,
	// 		validationSchema: Yup.object({
	// 			current_password: Yup.string()
	// 				.min(5, 'At least 5 characters')
	// 				.max(10, 'Must be 10 characters or less')
	// 				.required('Password required'),
	// 			password: Yup.string()
	// 				.min(5, 'At least 5 characters')
	// 				.max(10, 'Must be 10 characters or less')
	// 				.required('New password required'),
	// 			confirm_password: Yup.string()
	// 				.when('password', {
	// 					is: (val) => (val && val.length > 0 ? true : false),
	// 					then: Yup.string().oneOf(
	// 						[Yup.ref('password')],
	// 						'Password and Confirm Password need to be same',
	// 					),
	// 				})
	// 				.required('Confirm password required'),
	// 		}),
	// 			onSubmit: (values) => {
	// 			dispatch(handleChangePassword({ body: values }));
	// 			setIsCall(true);
	// },
	// 	});

	// Error Handle
	useEffect(() => {
		if (isCall) {
			errorMsg();
		}
	}, [changePasswordResponse]);

	const [isError, setIsError] = useState(false);
	const [isCall, setIsCall] = useState(false);
	const errorMsg = () => {
		if (changePasswordResponse.data.error) {
			setIsError(true);
			setIsCall(false);
			setTimeout(() => {
				setIsError(false);
			}, 2000);
		} else {
			navigate('/');
			setIsError(false);
			setIsCall(false);
		}
	};
	const boxStyle = {
		maxWidth: '380px',
		margin: '0 auto',
		padding: '1rem'
	};

	return (
		<div className='login_wrapper'>
			<Container className='mw-600' as='main'>
				<Row>
					<Col className='text-center'>
						<h1>Change Password</h1>
					</Col>
				</Row>
				<div style={boxStyle}>
					<Formik
						initialValues={initialValues}
						validationSchema={Yup.object({
							current_password: Yup.string()
								.min(5, 'At least 5 characters')
								.max(10, 'Must be 10 characters or less')
								.required('Password required'),
							password: Yup.string()
								.min(5, 'At least 5 characters')
								.max(10, 'Must be 10 characters or less')
								.required('New password required'),
							confirm_password: Yup.string()
								.when('password', {
									is: (val) => !!(val && val.length > 0),
									then: Yup.string().oneOf(
										[Yup.ref('password')],
										'Password and Confirm Password need to be same'
									)
								})
								.required('Confirm password required')
						})}
						onSubmit={(values) => {
							dispatch(handleChangePassword({ body: values }));
							setIsCall(true);
						}}
					>
						{({ values, errors, touched, handleSubmit }) => {
							return (
								<Form>
									<BootstrapForm.Group
										as={Col}
										controlId='current_password'
										className={`position-relative ${
											values.current_password.length > 0 && 'data_added'
										} ${
											touched.current_password && errors.current_password && 'field_error'
										}`}
									>
										<Field
											as={BootstrapForm.Control}
											type={hideShowValue.showPassword ? 'text' : 'password'}
											name='current_password'
										/>
										<Button
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											className='show_btn'
										>
											{hideShowValue.showPassword ? 'hide' : 'show'}
										</Button>
										<BootstrapForm.Label>Password *</BootstrapForm.Label>
										<ErrorMessage
											name='current_password'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='password'
										className={`position-relative ${
											values.password.length > 0 && 'data_added'
										} ${touched.password && errors.password && 'field_error'}`}
									>
										<Field
											as={BootstrapForm.Control}
											type={hideShowNewValue.showPassword ? 'text' : 'password'}
											name='password'
										/>
										<Button
											onClick={handleClickShowNewPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											className='show_btn'
										>
											{hideShowNewValue.showPassword ? 'hide' : 'show'}
										</Button>
										<BootstrapForm.Label>New Password *</BootstrapForm.Label>
										<ErrorMessage
											name='password'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='confirm_password'
										className={`position-relative ${
											values.confirm_password.length > 0 && 'data_added'
										} ${
											touched.confirm_password && errors.confirm_password && 'field_error'
										}`}
									>
										<Field
											as={BootstrapForm.Control}
											type={hideShowConfirmValue.showPassword ? 'text' : 'password'}
											name='confirm_password'
										/>
										<Button
											onClick={handleClickShowConfirmPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											className='show_btn'
										>
											{hideShowConfirmValue.showPassword ? 'hide' : 'show'}
										</Button>
										<BootstrapForm.Label>Confirm Password *</BootstrapForm.Label>
										<ErrorMessage
											name='confirm_password'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									{isError && changePasswordResponse.data.error && (
										<Alert variant='danger' dismissible>
											{changePasswordResponse.data.message.current_password[0]}
										</Alert>
									)}
									<Row className='ps-3'>
										<Button
											onClick={handleSubmit}
											type='submit'
											variant='contained password_change'
										>
											Change
										</Button>
										<Button
											variant='contained password_change'
											onClick={() => history.back()}
										>
											Cancel
										</Button>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Container>
		</div>
	);
};
export default ChangePassword;
