import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Row,
	Col,
	Button,
	Form as BootstrapForm,
	Alert
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogin } from '../../../redux/auth';
import Logo from '../../../assets/images/logo.svg';
import './style.scss';

const Login = () => {
	const dispatch = useDispatch();
	const { loginResponse, loading } = useSelector((state) => state.auth);
	const [hideShowValue, setValues] = useState({
		password: '',
		showPassword: false
	});
	const handleClickShowPassword = () => {
		setValues({
			...hideShowValue,
			showPassword: !hideShowValue.showPassword
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Formik and YUP validation
	const initialValues = {
		email: '',
		password: ''
	};

	useEffect(() => {
		if (isCall) {
			errorMsg();
		}
	}, [loginResponse]);

	const [isError, setIsError] = useState(false);
	const [isCall, setIsCall] = useState(false);
	const errorMsg = () => {
		if (loginResponse.error) {
			setIsError(true);
			setIsCall(false);
			setTimeout(() => {
				setIsError(false);
			}, 2000);
		} else {
			setIsError(false);
			setIsCall(false);
		}
	};

	if (loading) {
		return (
			<div className='loader_section'>
				<h6>Loading...</h6>
			</div>
		);
	}

	const boxStyle = {
		maxWidth: '380px',
		margin: '0 auto',
		padding: '1rem'
	};

	return (
		<div className='login_wrapper'>
			<Container className='mw-600' as='main'>
				<Row>
					<Col className='text-center'>
						<img src={Logo} />
					</Col>
				</Row>
				<div style={boxStyle}>
					<Formik
						initialValues={initialValues}
						validationSchema={Yup.object({
							password: Yup.string()
								.min(5, 'At least 5 characters')
								.max(10, 'Must be 10 characters or less')
								.required('password Required'),
							email: Yup.string()
								.email('Invalid email address')
								.required('email Required')
						})}
						onSubmit={(values) => {
							dispatch(handleLogin({ body: values }));
							setIsCall(true);
						}}
					>
						{({ values, errors, touched, handleSubmit }) => {
							return (
								<Form>
									<BootstrapForm.Group
										as={Col}
										controlId='email'
										className={`position-relative ${
											values.email.length > 0 && 'data_added'
										} ${touched.email && errors.email && 'field_error'}`}
									>
										<Field
											as={BootstrapForm.Control}
											type='email'
											name='email'
										/>
										<BootstrapForm.Label>Email *</BootstrapForm.Label>
										<ErrorMessage
											name='email'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									<BootstrapForm.Group
										as={Col}
										controlId='password'
										className={`position-relative ${
											values.password.length > 0 && 'data_added'
										} ${touched.password && errors.password && 'field_error'}`}
									>
										<Field
											as={BootstrapForm.Control}
											type={hideShowValue.showPassword ? 'text' : 'password'}
											name='password'
										/>
										<Button
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
											className='show_btn'
										>
											{hideShowValue.showPassword ? 'hide' : 'show'}
										</Button>
										<BootstrapForm.Label>Password *</BootstrapForm.Label>
										<ErrorMessage
											name='password'
											render={(msg) => <span className='error_message'>{msg}</span>}
										/>
									</BootstrapForm.Group>
									{isError && loginResponse.error && (
										<Alert variant='danger' dismissible>
											{loginResponse.message.password
												? loginResponse.message.password[0]
												: loginResponse.message || 'Please try after some time'}
										</Alert>
									)}
									<Button onClick={handleSubmit} type='submit' variant='contained'>
										Login
									</Button>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Container>
		</div>
	);
};

export default Login;
