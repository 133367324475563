/* eslint-disable */
import React from "react";
import { Modal, Button } from "react-bootstrap";

function CustomModal(props) {
  const { title, size, content, footerButtons, show, handleClose, className } =
    props;

  return (
    <Modal
      className={className}
      show={show}
      onHide={handleClose}
      size={size}
      centered
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      {footerButtons && (
        <Modal.Footer>
          {footerButtons.map((button, index) => (
            <Button
              key={index}
              variant={button.variant}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default CustomModal;
