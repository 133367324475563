import React, { memo } from 'react';
import QuestionSet from '../QuestionSet';

const QuestionSetContainer = ({ item, handleClick }) => {
	return (
		<div className='questions_set_box'>
			<h2>{item.cat_title} :</h2>
			<div className='questions_set_list'>
				{item.set.map((question, index) => {
					return (
						<QuestionSet key={index} question={question} handleClick={handleClick} />
					);
				})}
			</div>
		</div>
	);
};

export default memo(QuestionSetContainer);
