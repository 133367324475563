/* eslint-disable */
import React, { useState } from "react";
import { Container, Form, InputGroup, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as formik from "formik";
import * as yup from "yup";
import "./styles.scss";
import EmailTempalte from "./template/emailPreview";
import { useDispatch, useSelector } from "react-redux";
import { sendAdminEmail } from "../../../redux/admin";
const initialValues = {
  subject: "",
  text_message: "",
};

const schema = yup.object().shape({
  subject: yup.string().required(),
  text_message: yup.string().required(),
});

const AdminEmail = () => {
  const [fieldValue, setFieldValue] = useState([true, false, false]);
  const items = [
    "Important updates",
    "Product updates",
    "General communications",
  ];
  const handleChangeCheckbox = (e, index) => {
    const { checked } = e.target;
    setFieldValue((prev) => {
      const newFieldValue = [...prev];
      newFieldValue[index] = checked;
      return newFieldValue;
    });
  };

  const dispatch = useDispatch();
  const { adminEmailData } = useSelector((state) => state?.admin);
  console.log("adminEmailData ", adminEmailData);

  const onSubmit = (data, { resetForm }) => {
    const updates = {
      important_updates: fieldValue[0],
      product_updates: fieldValue[1],
      general_communications: fieldValue[2],
    };
    console.log("data lllllllllllll ", data, updates);
    dispatch(sendAdminEmail({ ...data, updates: updates }));
    resetForm();
  };

  return (
    <Container fluid>
      <formik.Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Row className="justify-content-start mx-auto admin-mail-box">
            <Col xs={12} md={6} fluid>
              <section className="security_section mt-0">
                <div className="security_wrapper devide-sec mb-1 ">
                  {/* <h2>Email subscription</h2> */}

                  <h4 className="receive-email mb-1">Receive email about:</h4>
                  <Form>
                    <div className="d-flex flex-row">
                      {items.map((item, index) => (
                        <Form.Check key={index} className="me-4 mb-0">
                          <div className="d-inline-flex align-items-center">
                            <Form.Check.Input
                              type="checkbox"
                              id={`${index}`}
                              name={`${index}`}
                              onChange={(e) => handleChangeCheckbox(e, index)}
                              checked={fieldValue[index]}
                              value={index}
                              className={fieldValue[index] ? "bg_btn" : ""}
                            />
                            <Form.Check.Label className="notify-items mb-0 ms-1">
                              {item}
                            </Form.Check.Label>
                          </div>
                        </Form.Check>
                      ))}
                    </div>
                  </Form>
                </div>
              </section>
              <section>
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        isInvalid={errors?.subject && touched.subject}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationFormik102"
                      className="position-relative"
                    >
                      <Form.Label>Text message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={7}
                        type="text"
                        placeholder="Text message"
                        name="text_message"
                        value={values.text_message}
                        onChange={handleChange}
                        isInvalid={errors?.text_message && touched.text_message}
                      />
                    </Form.Group>
                  </Row>

                  <Button type="submit" className="bg_btn">
                    Send mail
                  </Button>
                </Form>
              </section>
            </Col>
            <Col xs={12} md={6}>
              <h3>Preview:</h3>
              <EmailTempalte
                subject={values?.subject}
                message={values?.text_message}
                valediction1={values?.valediction1}
                valediction2={values?.valediction2}
              />
            </Col>
          </Row>
        )}
      </formik.Formik>
    </Container>
  );
};

export default AdminEmail;
