import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import timeIcon from '../../../assets/images/icons/timer-icon.svg';

const QuestionSet = ({ question, handleClick }) => {
	const toolTipText = (draft) => {
		if (draft) {
			return 'Draft saved';
		}
		return 'Submitted for grading';
	};

	return (
		<div
			className='questions_set_item'
			onClick={() =>
				handleClick(
					question.set_url,
					question.set_id,
					question.timed,
					question.submitted,
					question.time_hrs,
					question.time_mins,
					question.q_count,
					question.time_x
				)
			}
		>
			{(question.submitted || question.draft_save) && (
				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip>
							<strong>{toolTipText(question.draft_save)}</strong>
						</Tooltip>
					}
				>
					<div className={`promo-label ${question.draft_save && 'draft-label'}`}>
						<span>*</span>
					</div>
				</OverlayTrigger>
			)}
			{question.timed && <ReactSVG src={timeIcon} className='timed_icon' />}
			<div className='questions_set'>
				{/* <div dangerouslySetInnerHTML={{ __html: question.button_icon }}></div> */}
				{question.button_icon?.slice(-3) === 'svg'
					? (
						<ReactSVG src={question.button_icon} className='button_icon_svg' />
					)
					: (
						<img src={question.button_icon} />
					)}
				<h4>{question.button_text}</h4>
			</div>
		</div>
	);
};

export default memo(QuestionSet);
