/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull, debounce } from "lodash";
import { Form, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader";
import {
  getStudentsListSpecial1,
  handleGetStudentsList,
  approveDeySpecial1Offer,
  approveSpecial1Subscription,
  getStudentsListSpecial1Pending,
  deleteSpecial1ApprovedRequests,
} from "../../../redux/admin";
import LineChartIcon from "../../../assets/images/chart.png";
import "./style.scss";
import { MdOutlineEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CustomModal from "../CustomModal";
// import UpdatePlan from './updatePlan';
const Special1OfferStudents = () => {
  const [sort, setSort] = useState(null);
  const [sortField, setSortField] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("pending");
  const [page, setPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rowData, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loadingForStudentList,
    totalData,
    special1OfferList,
    special1OfferUpdateUser,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getStudentsListSpecial1Pending({}));
  }, []);

  useEffect(() => {
    const params = {
      page: page,
      limit,
      search: searchInput,
      sort: sortField,
      orderBy: sort,
    };

    if (filter === "all") {
      dispatch(getStudentsListSpecial1(params));
    } else {
      dispatch(getStudentsListSpecial1Pending(params));
    }
  }, [page, sort, sortField, searchInput, filter]);

  const handleApproveDeny = (params, type) => {
    const data = {
      _id: params._id,
      email: params.email,
      userId: params.userId,
      duration: params?.duration,
      approve: type === "approved" ? true : null,
      deny: type === "deny" ? true : null,
    };
    dispatch(approveDeySpecial1Offer(data));
    if (type === "approved") {
      toast.success("Special1 subscription has been approved");
    } else {
      toast.success("Special1 subscription has been denied");
    }
    const values = {
      userId: params.userId,
      username: params.username,
      email: params.email,
      subscription_type: type === "approved" ? "Special1" : "Basic",
      duration: "3 months",
      admin_note:
        type === "approved"
          ? "Special1 request approved by admin."
          : "Special1 request denied by admin.",
      renew: false,
    };
    dispatch(approveSpecial1Subscription({ values: values, searchInput: "" }))
      .unwrap()
      .then((res) => {
        if (filter === "all" && res) {
          dispatch(
            getStudentsListSpecial1({
              page: page,
              limit,
              search: searchInput,
              sort: sortField,
              orderBy: sort,
            })
          );
        } else if (res) {
          dispatch(
            getStudentsListSpecial1Pending({
              page: page,
              limit,
              search: searchInput,
              sort: sortField,
              orderBy: sort,
            })
          );
        }
      });
  };

  const handleDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      //timeZoneName: "short",
    };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  const columns = [
    {
      id: "id",
      name: "SN",
      width: 100,
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
      className: "cs-column-1",
    },
    {
      id: "username",
      name: "Username",
      minWidth: 150,
      selector: (row) => row.username,
      sortable: true,
      sortField: "username",
      cell: (params) => {
        const tooltip = (
          <Tooltip id="tooltip">
            {params?.username + " " + params?.email}
          </Tooltip>
        );
        return (
          <>
            <Button
              onClick={() =>
                navigate(`/user-details/${params.userId}`, {
                  state: params.userId,
                })
              }
              style={{ color: "black" }}
              className="px-0"
            >
              <OverlayTrigger placement="top" overlay={tooltip}>
                <Button bsStyle="default" className="px-0">
                  {params.username}
                </Button>
              </OverlayTrigger>
            </Button>
            <Button
              onClick={() => navigate(`/user-dashboard/${params.userId}`)}
            >
              <img src={LineChartIcon} style={{ width: "12px" }} />
            </Button>
          </>
        );
      },
    },
    {
      id: "textarea",
      name: "textarea",
      minWidth: 150,
      selector: (row) => row.username,
      sortable: true,
      sortField: "textarea",
      cell: (params) => {
        const textPreview = params?.textarea
          ? params.textarea.substring(0, 8) + "..."
          : "--";
        return params?.textarea ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{params.textarea}</Tooltip>}
          >
            <Button bsStyle="default" className="px-0">
              {textPreview}
            </Button>
          </OverlayTrigger>
        ) : (
          <Button bsStyle="default" className="px-0">
            {textPreview}
          </Button>
        );
      },
    },

    {
      id: "updated_at",
      name: "Request Received",
      minWidth: 110,
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "updated_at",
      cell: (params) => {
        return handleDate(params?.created_at);
      },
    },

    {
      id: "status",
      name: "Status",
      minWidth: 150,
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
      cell: (params) => {
        return (
          <>
            {/* <MdOutlineEdit style = {{cursor: 'pointer'}} onClick={() => (setShow(true), setData(params))} /> */}
            {params.status ? params.status : "--"}
          </>
        );
      },
    },

    {
      id: "actions",
      name: "Actions",
      minWidth: 110,
      selector: (row) => row.updated_at,
      sortable: true,
      sortField: "actions",
      cell: (params) => {
        return params.status === "Pending" ? (
          <>
            <Button
              variant="success"
              style={{ backgroundColor: "green", color: "white" }}
              className="text-nowrap"
              onClick={() => handleApproveDeny(params, "approved")}
              disabled={params.approved === true}
            >
              Approve
            </Button>
            <Button
              variant="primary"
              style={{ backgroundColor: "red", color: "white" }}
              className="ms-2 text-nowrap"
              onClick={() => handleApproveDeny(params, "deny")}
              disabled={params.deny === true}
            >
              Deny
            </Button>
          </>
        ) : (
          "--"
        );
      },
    },
  ];

  function createData(
    id,
    _id,
    name,
    email,
    type,
    joinedDate,
    userId,
    approved,
    deny,
    duration,
    status,
    textarea,
    created_at
  ) {
    return {
      id,
      _id,
      username: name,
      email,
      subscription_type: type,
      updated_at: joinedDate,
      userId,
      approved,
      deny,
      duration,
      status,
      textarea,
      created_at,
    };
  }

  const rowsData = [
    special1OfferList?.users &&
      special1OfferList?.users.map((item, index) => {
        return createData(
          index + 1,
          item._id,
          item.username,
          item.email,
          item.subscription_type,
          moment(item.created_at, "ddd, DD MMM YYYY HH:mm:ss z").format(
            "DD MMM YYYY"
          ),
          item.userId,
          item.approved,
          item.deny,
          item?.duration,
          item?.status,
          item?.textarea,
          item?.created_at
        );
      }),
  ];

  const handlePageChange = (value) => {
    setPage(value);

    const params = {
      page: value,
      limit,
      search: searchInput,
      sort: sortField,
      orderBy: sort,
    };

    if (filter === "all") {
      dispatch(getStudentsListSpecial1(params));
    } else {
      dispatch(getStudentsListSpecial1Pending(params));
    }
  };

  const handleSortModelChange = (column, sortDirection) => {
    const orderBy = sortDirection === "asc" ? 1 : -1;
    setSort(orderBy);
    setSortField(column.sortField);

    const params = {
      page: page,
      limit,
      search: searchInput || "",
      sort: column.sortField,
      orderBy: orderBy,
    };

    if (filter === "all") {
      dispatch(getStudentsListSpecial1(params));
    } else {
      dispatch(getStudentsListSpecial1Pending(params));
    }
  };

  // Search
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (filter === "all") {
      dispatch(
        getStudentsListSpecial1({
          page: 1, // Reset to first page on search
          limit,
          search: value,
          sort: sortField,
          orderBy: sort,
        })
      );
    } else {
      dispatch(
        getStudentsListSpecial1Pending({
          page: 1, // Reset to first page on search
          limit,
          search: value,
          sort: sortField,
          orderBy: sort,
        })
      );
    }
  };

  const onSearchDebounce = debounce(handleSearch, 1500);

  const handleSpecialiUserList = (value) => {
    setSort(-1);
    setFilter(value);
    if (value === "all") {
      dispatch(
        getStudentsListSpecial1({
          page: page,
          limit,
          search: searchInput,
          sort: sortField,
          orderBy: -1,
        })
      );
    } else {
      dispatch(
        getStudentsListSpecial1Pending({
          page: page,
          limit,
          search: searchInput,
          sort: sortField,
          orderBy: -1,
        })
      );
    }
  };

  const deleteApprovedSpecial1Requests = () => {
    setFilter("deleteApproved");
    dispatch(deleteSpecial1ApprovedRequests())
      .unwrap()
      .then((res) => {
        if (res?.response?.status === 200) {
          toast.success(res?.response?.data?.message);
        } else if (res?.response?.status === 201) {
          toast.error(res?.response?.data?.message);
        } else {
          toast.success("Some thing went wrong!");
        }
      });
    setShowConfirmModal(false);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      !loadingForStudentList && (
        <>
          <h5 className="me-auto">Special1 Requests</h5>
          <Button
            style={{
              boxShadow:
                filter === "deleteApproved"
                  ? "0 0 0 0rem rgba(246, 150, 104, 0.5)"
                  : "",
            }}
            className={`custom-button-tab btn ${
              filter === "deleteApproved"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => setShowConfirmModal(true)}
          >
            Delete Approved
          </Button>

          <Button
            style={{
              boxShadow:
                filter === "pending"
                  ? "0 0 0 0rem rgba(246, 150, 104, 0.5)"
                  : "",
            }}
            className={`custom-button-tab btn ${
              filter === "pending"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => handleSpecialiUserList("pending")}
          >
            Show Pending
          </Button>
          <Button
            style={{
              boxShadow:
                filter === "all" ? "0 0 0 0rem rgba(246, 150, 104, 0.5)" : "",
            }}
            className={`btn custom-button-tab ${
              filter === "all"
                ? "custom-button-tab-active"
                : "btn-orange-outline"
            }`}
            onClick={() => handleSpecialiUserList("all")}
          >
            Show All
          </Button>
          <Form.Control
            id="search_input"
            type="text"
            onChange={onSearchDebounce}
            defaultValue={searchInput}
            className="search_input"
            placeholder="search"
          />
        </>
      )
    );
  }, [searchInput, filter, loadingForStudentList]);

  if (isNull(rowsData[0])) return;

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const modalTitle = "Delete";
  const modalContent = (
    <p>Are you want to delete all Special1 approved requests!</p>
  );
  const modalFooterButtons = [
    { label: "Close", variant: "danger", onClick: handleCloseConfirmModal },
    {
      label: "Delete",
      variant: "primary",
      onClick: deleteApprovedSpecial1Requests,
    },
  ];

  return (
    <div className="dashboard_wrapper">
      <Header className="pe-0" />
      <Container
        className="pt-5 fixing-loader inner-speacoal-off1"
        style={{ minHeight: "calc(100vh - 141px)" }}
      >
        <div
          style={{ width: "100%" }}
          className="d-flex flex-wrap userlist_wrapper"
        >
          <DataTable
            //title=""
            columns={columns}
            data={rowsData[0]}
            progressPending={loadingForStudentList}
            progressComponent={<Loader />}
            highlightOnHover
            pagination
            paginationServer
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationTotalRows={special1OfferList?.total}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            onSort={handleSortModelChange}
            sortServer
          />
        </div>

        <CustomModal
          size={"md"}
          title={modalTitle}
          content={modalContent}
          footerButtons={modalFooterButtons}
          show={showConfirmModal}
          handleClose={handleCloseConfirmModal}
        />
      </Container>
      <div className="footer">
        <h5 className="text-center">
          &copy; {new Date().getFullYear()} boo9 inc.
        </h5>
      </div>
    </div>
  );
};

export default Special1OfferStudents;
