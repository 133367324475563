/* eslint-disable */
import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import { Form } from "react-bootstrap";
import SoundIcon from "../../../assets/images/icons/sound-icon.svg";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import RightAnswereComponent from "../RightAnswereComponent";
import {
  questionCorrectnessType1,
  attemptedQuestionCounterType1,
  correctQuestionVal,
  handleChange,
} from "../../../utils/practice";
import "./styles.scss";

const DictationQ = ({
  data,
  value,
  indexValue,
  active,
  onPress,
  fieldValue,
  name,
  setFieldVal,
}) => {
  useEffect(() => {
    attemptedQuestionCounterType1(data, value, indexValue, fieldValue);
    correctQuestionVal(fieldValue, setFieldVal, name);
  }, [value]);

  return (
    <div
      id={`${data.q_type}_${data.qno}`}
      className="faq_base_display_componet"
    >
      <div className="dtq_display">
        <div className="question_number">
          <h6>{data.qno}</h6>
          <QuestionScoreComponent data={data} />
          <QueExplanationComponent
            data={data}
            explanations={data.explanations}
          />
        </div>
        <div className="content">
          <ReactSVG
            src={SoundIcon}
            className={`sound_icon ${active && "active"}`}
            onClick={onPress}
          />
          <div className="input-tooltip">
            <Form.Control
              name={name}
              id={data.qno}
              type="text"
              onChange={(e) => {
                handleChange(e, setFieldVal, name);
              }}
              value={fieldValue || ""}
              disabled={data.ans_obj}
              className={data.ans_obj && questionCorrectnessType1(data)}
            />
            <RightAnswereComponent data={data} className="test" />
          </div>
          <div className="icons">
            <QuestionHintComponent hints={data.hints} />
          </div>
        </div>
      </div>
      <div className="mobile_dtq_display">
        <div className="input-tooltip">
          <Form.Control
            name={name}
            id={data.qno}
            type="text"
            onChange={(e) => {
              handleChange(e, setFieldVal, name);
            }}
            value={fieldValue || ""}
            disabled={data.ans_obj}
            className={data.ans_obj && questionCorrectnessType1(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default DictationQ;
