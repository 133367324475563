/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleGetSignupInfo } from "../../redux/admin";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import Students from "../Admin/Students";
import { PieChart } from "../../components/PieChart";
import { isEmpty } from "lodash";
import { reset } from "../../redux/auth/slice";
import "./style.scss";
import Header from "../../components/Header";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const { changePasswordResponse, loginResponse } = useSelector(
    (state) => state.auth
  );
  // const { loading } = useSelector(
  // 	(state) => state.admin
  // );

  useEffect(() => {
    dispatch(handleGetSignupInfo({ data: true }));
  }, []);

  useEffect(() => {
    if (!isEmpty(changePasswordResponse)) {
      setSuccessMessage(changePasswordResponse.message);
      setTimeout(() => {
        setSuccessMessage("");
        dispatch(reset());
      }, 3000);
    }
  }, []);

  // Signup info
  const { signupInfo } = useSelector((state) => state.admin);

  // Change Password
  const changePassword = () => {
    navigate("/change-password");
  };

  // if (loading) {
  // 	return (
  // 		<div className='loader_section'>
  // 			<h6>Loading...</h6>
  // 		</div>
  // 	);
  // }

  return (
    <div className="dashboard_wrapper">
      <Header />
      {!isEmpty(successMessage) && (
        <Button variant="link" severity="success">
          {successMessage}
        </Button>
      )}
      <div className="signup_info">
        <Container>
          <Row>
            <Col>
              <h3>Signup info</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Table className="signup_table">
                <tbody>
                  <tr>
                    <td></td>
                    <td>Basic</td>
                    <td>Premium</td>
                    <td>All</td>
                  </tr>
                  <tr>
                    <td>Total no. of users:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.total_users.basic}</td>
                        <td>{signupInfo.total_users.premium}</td>
                        <td>{signupInfo.total_users.all}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>No. of signups today:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.signup_today.basic}</td>
                        <td>{signupInfo.signup_today.premium}</td>
                        <td>{signupInfo.signup_today.all}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>No. of signups last 7 days:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.signup_last_7.basic}</td>
                        <td>{signupInfo.signup_last_7.premium}</td>
                        <td>{signupInfo.signup_last_7.all}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>No. of signups last 1 month:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.signup_last_1_month.basic}</td>
                        <td>{signupInfo.signup_last_1_month.premium}</td>
                        <td>{signupInfo.signup_last_1_month.all}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>No. of signups last 6 months:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.signup_last_6_month.basic}</td>
                        <td>{signupInfo.signup_last_6_month.premium}</td>
                        <td>{signupInfo.signup_last_6_month.all}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>No. of signups last 1 year:</td>
                    {signupInfo && (
                      <>
                        <td>{signupInfo.signup_last_1_year.basic}</td>
                        <td>{signupInfo.signup_last_1_year.premium}</td>
                        <td>{signupInfo.signup_last_1_year.all}</td>
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={4}>
              <PieChart />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="userlist_wrapper">
        <div className="userlist_table">
          <Students />
        </div>
      </div>
      <div className="security_wrapper">
        <Container>
          <h3>Security</h3>
          <Button onClick={changePassword}>
            Change admin password and/or pin
          </Button>
          <h5>
            Last login:{" "}
            {moment(
              loginResponse.data.last_login.login_time,
              "ddd, DD MMM YYYY HH:mm:ss z"
            ).format("dddd MMM DD, YYYY")}{" "}
          </h5>
        </Container>
      </div>
      <div className="footer">
        <h5 className="text-center">
          &copy; {new Date().getFullYear()} boo9 inc.
        </h5>
      </div>
    </div>
  );
};

export default Dashboard;
