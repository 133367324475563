import axios from './axios';

// Body convert into formdata
const getFormData = object => Object.keys(object).reduce((formData, key) => {
	formData.append(key, object[key]);
	return formData;
}, new FormData());

// Gives authToken
const token = () => localStorage.getItem('userToken');

// Provides Headers
const headers = () => {
	return {
		headers: {
			Authorization: `Bearer ${token()}`
		}
	};
};

// Login
export const callPostAPI = async ({ body }) => {
	const data = getFormData(body);
	return await axios.post('admin/login', data)
		.then((res) => res)
		.catch((err) => err);
};

// SignUp
export const userSignUp = async ({ body }) => {
	const data = getFormData(body);
	return await axios.post('auth/signup', data)
		.then((res) => res)
		.catch((err) => err);
};

// ChangePassword
export const userChangePassword = async ({ body }) => {
	const data = getFormData(body);
	return await axios.post('admin/change-password', data, headers())
		.then((res) => res)
		.catch((err) => err);
};
