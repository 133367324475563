/* eslint-disable */
import { includes, isArray, isEmpty, isNull, isUndefined, has } from "lodash";

export const questionCorrectnessType1 = (data, index) => {
  // if (data.grading_type !== 'instant') return '';

  if (
    !data?.ans_obj?.ugot_right ||
    (isArray(data?.ans_obj?.ugot_right) && !data?.ans_obj?.ugot_right[index])
  ) {
    return "question_ans_wrong";
  }
  return "question_ans_right";
};

export const questionCorrectnessType2 = (data, index) => {
  // if (data.grading_type !== 'instant') return '';

  if (data?.ans_obj?.correctness === "unattempted") {
    if (data.ans_obj?.ans_key === index) {
      return "question_ans_right";
    }
    return "question_ans_wrong";
  }
  if (data?.ans_obj?.ans_key === index) {
    return "question_ans_right";
  }
  if (data?.attempted_obj === index) {
    return "question_ans_wrong";
  }
  return "";
};

export const mcqQuestionCorrectness = (data, index) => {
  // if (data.grading_type !== 'instant') return '';

  if (data?.ans_obj?.correctness === "unattempted") {
    if (data.ans_obj?.ans_key[index] === true) {
      return "question_ans_right";
    }
    return "question_ans_wrong";
  }
  if (data?.ans_obj?.ans_key[index] === true) {
    return "question_ans_right";
  }
  if (
    data?.attempted_obj &&
    data.attempted_obj[index] === true &&
    data.ans_obj?.ans_key[index] === false
  ) {
    return "question_ans_wrong";
  }
  return "";
};

export const fibQuestionCorrectness = (data, element) => {
  // if (data.grading_type !== 'instant') return '';

  if (data?.ans_obj?.correctness === "unattempted") {
    if (data.ans_obj?.ans_key === element[1]) {
      return "question_ans_right";
    }
    return "question_ans_wrong";
  }
  if (data?.ans_obj?.ans_key === element[1]) {
    return "question_ans_right";
  }
  if (data?.attempted_obj === element[1]) {
    return "question_ans_wrong";
  }
  return "";
};

// export const inputValueType1 = (data, value, { i, j, k }) => {
// 	if (
// 		!isNull(value.list_sections[i].list_ags[j].list_ans[k].stud_ans) &&
// 		!/^\s/g.test(value.list_sections[i].list_ags[j].list_ans[k].stud_ans)
// 	) {
// 		return value.list_sections[i].list_ags[j].list_ans[k].stud_ans;
// 	}
// 	return '';
// };

// export const inputValueType2 = (data, value, { i, j, k }, index) => {
// 	if (
// 		!isNull(value.list_sections[i].list_ags[j].list_ans[k].stud_ans) &&
// 		!/^\s/g.test(value.list_sections[i].list_ags[j].list_ans[k].stud_ans[index])
// 	) {
// 		if (
// 			!isUndefined(value.list_sections[i].list_ags[j].list_ans[k].stud_ans[index])
// 		) {
// 			return value.list_sections[i].list_ags[j].list_ans[k].stud_ans[index];
// 		}
// 		return '';
// 	}
// 	return '';
// };

export const inputValueType3 = (data, value, { i, j, k }, index) => {
  if (
    isNull(value.list_sections[i].list_ags[j].list_ans[k].stud_ans) ||
    isUndefined(value.list_sections[i].list_ags[j].list_ans[k].stud_ans[index])
  ) {
    return false;
  }
  return value.list_sections[i].list_ags[j].list_ans[k].stud_ans[index];
};

export const correctQuestionVal = (fieldValue, setFieldVal, name) => {
  if (fieldValue === "") {
    setFieldVal(name, null);
  }
};

export const correctQuestionValType2 = (
  data,
  fieldValue,
  setFieldVal,
  name
) => {
  if (isEmpty(fieldValue)) return;
  const temp = ["FillGroupQ", "MatchItemsQ", "FillInlineQ"];
  if (temp.includes(data.q_type) && fieldValue.every((ele) => ele === null)) {
    setFieldVal(name, null);
  }
  if (
    data.q_type === "MultipleChoiceQ" &&
    fieldValue.every((ele) => ele === false)
  ) {
    setFieldVal(name, null);
  }
};

export const attemptedQuestionCounterType1 = (
  data,
  value,
  { i },
  fieldValue
) => {
  if (!value || !value.list_sections || !value.list_sections[i]) {
    return;
  }
  if (isEmpty(value.list_sections[i])) return;
  if (!isEmpty(fieldValue) && !/^\s/g.test(fieldValue)) {
    value.list_sections[i].dict_qno_attempted[data.qno] = true;
  } else {
    value.list_sections[i].dict_qno_attempted[data.qno] = false;
  }
};

export const attemptedQCounterForMatchItems = (
  data,
  value,
  { i },
  fieldValue
) => {
  if (isEmpty(value.list_sections[i])) return;
  if (!isEmpty(fieldValue) && !fieldValue.every((ele) => ele === null)) {
    value.list_sections[i].dict_qno_attempted[data.qno] = true;
  } else {
    value.list_sections[i].dict_qno_attempted[data.qno] = false;
  }
};

export const attemptedQuestionCounterType4 = (
  data,
  value,
  { i },
  fieldValue
) => {
  if (isEmpty(value)) return;
  if (isEmpty(value.list_sections[i])) return;
  if (!isNull(fieldValue)) {
    value.list_sections[i].dict_qno_attempted[data.qno] = true;
  } else {
    value.list_sections[i].dict_qno_attempted[data.qno] = false;
  }
};

export const attemptedQuestionCounterType2 = (
  index,
  data,
  value,
  { i, j, k }
) => {
  if (!isNull(value.list_sections[i].list_ags[j].list_ans[k].stud_ans)) {
    if (!data) return;
    for (let l = 0; l < data.length; l++) {
      if (
        isUndefined(value.list_sections[i].list_ags[j].list_ans[k].stud_ans[l])
      ) {
        value.list_sections[i].list_ags[j].list_ans[k].stud_ans[l] = false;
      }
    }
    if (
      includes(value.list_sections[i].list_ags[j].list_ans[k].stud_ans, true)
    ) {
      value.list_sections[i].dict_qno_attempted[index] = true;
    } else {
      value.list_sections[i].dict_qno_attempted[index] = false;
    }
  }

  if (isNull(value.list_sections[i].list_ags[j].list_ans[k].stud_ans)) {
    value.list_sections[i].dict_qno_attempted[index] = false;
  }
};

export const attemptedQuestionCounterType3 = (
  data,
  value,
  { i },
  fieldValue,
  list
) => {
  if (!value) return;
  if (isNull(fieldValue) && !isEmpty(value.list_sections[i])) {
    value.list_sections[i].dict_qno_attempted[data.qno] = false;
  }
  if (!isNull(fieldValue) && !/^\s/g.test(fieldValue)) {
    if (!isEmpty(value.list_sections[i])) {
      value.list_sections[i].dict_qno_attempted[data.qno] = true;
    }

    for (let l = 0; l < list.length; l++) {
      if (isUndefined(fieldValue[l])) {
        fieldValue[l] = "";
      }
    }
  }
};

export const handleChange = (e, setFieldVal, name) => {
  if (!/^\s/g.test(e.target.value)) {
    if (isEmpty(e.target.value)) {
      setFieldVal(name, null);
    } else setFieldVal(name, e.target.value);
  }
};

export const getFieldVal = (values, i, j, k) => {
  if (
    !isEmpty(values) &&
    !isEmpty(values.list_sections) &&
    !isEmpty(values.list_sections[i]) &&
    !isEmpty(values.list_sections[i].list_ags) &&
    !isEmpty(values.list_sections[i].list_ags[j]) &&
    !isEmpty(values.list_sections[i].list_ags[j].list_ans) &&
    !isEmpty(values.list_sections[i].list_ags[j].list_ans[k])
  ) {
    return values.list_sections[i].list_ags[j].list_ans[k].stud_ans;
  } else return null;
};

export function msToSeconds(ms) {
  return ms / 1000;
}

export const getQuestionIndex = (questionList, data) => {
  if (isEmpty(questionList)) return null;
  let found1 = false;
  let found2 = false;
  let a = 0;
  let b = 0;
  let c = 0;
  for (let i = 0; i < questionList.list_sections.length; i++) {
    a = i;
    for (let j = 0; j < questionList.list_sections[i].list_qgs.length; j++) {
      b = j;
      for (
        let k = 0;
        k < questionList.list_sections[i].list_qgs[j].list_ques.length;
        k++
      ) {
        c = k;
        if (
          questionList.list_sections[i].list_qgs[j].list_ques[k].qno ===
          data.qno
        ) {
          found1 = true;
          break;
        }
      }
      if (found1) {
        found2 = true;
        break;
      }
    }
    if (found2) {
      break;
    }
  }
  return { i: a, j: b, k: c };
};

export const getQuestionNumber = (values, e) => {
  let a = 0;
  for (let i = 0; i < values.list_sections.length; i++) {
    a = i;
    if (has(values.list_sections[i].dict_qno_attempted, e.qno)) {
      break;
    }
  }
  return a;
};
