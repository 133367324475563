import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export const PublicRoute = ({ children }) => {
	const { isLoggedIn } = useSelector((state) => state.auth);
	const location = useLocation();
	if (!isLoggedIn) {
		return children;
	}
	return <Navigate to="/" state={{ from: location }} />;
};
