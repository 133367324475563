import { isEmpty } from 'lodash';
import React from 'react';

const QuestionParagraph = ({ group }) => {
	if (isEmpty(group.paras)) return;

	return (
		<div className='mixed_english_reading_group'>
			<div className='mixed_english_reading_box'>
				<h4>{group.group_title}</h4>
				{group.paras.map((paras, k) => (
					<p key={k}>{paras}</p>
				))}
			</div>
		</div>
	);
};

export default QuestionParagraph;
