/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import {
  handleGetStudentsList,
  handleGetPaidStudent,
  handleGetUserDetails,
  handleGetSignupInfo,
  handleGetUserScore,
  approveSpecial1Subscription,
  getSpecial1OfferUser,
  getStudentsListSpecial1,
  getStudentsListSpecial1Pending,
  approveDeySpecial1Offer,
  clearApproveUser,
  adminResetCounts,
  getFlaggedQuestions,
  getFlagQuestion,
  sendAdminEmail,
} from "../admin";

const initialState = {
  loading: false,
  loadingForStudentList: false,
  error: false,
  studentList: null,
  paidStudents: null,
  userDetail: null,
  userScore: null,
  signupInfo: null,
  totalData: 0,
  approvedSpecial1: {},
  approvedUser: {},
  special1OfferList: [],
  special1OfferUpdateUser: {},
  adminresCounts: {},
  flaggedQuestionsData: {},
  loadingForFlagList: false,
  flagQuestion: {},
  adminEmailData: {},
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [handleGetStudentsList.fulfilled]: (state, { payload }) => {
      state.studentList = payload?.response?.data?.data;
      state.totalData = payload?.response?.data?.data?.total;
      state.loadingForStudentList = false;
    },
    [handleGetStudentsList.pending]: (state) => {
      state.loadingForStudentList = true;
    },
    [handleGetStudentsList.rejected]: (state) => {
      state.error = true;
    },

    [handleGetPaidStudent.fulfilled]: (state, { payload }) => {
      state.paidStudents = payload.response.data.data;
      state.totalData = payload.response.data.data.total;
    },
    [handleGetPaidStudent.pending]: (state) => {
      state.loading = true;
    },
    [handleGetPaidStudent.rejected]: (state) => {
      state.error = true;
    },

    [handleGetUserDetails.fulfilled]: (state, { payload }) => {
      if (payload.response.data) {
        state.userDetail = payload.response.data.data;
      } else {
        state.userDetail = payload.response.response.data;
      }
      state.loading = false;
    },
    [handleGetUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [handleGetUserDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [handleGetUserScore.fulfilled]: (state, { payload }) => {
      if (payload.response.data) {
        state.userScore = payload.response.data.data;
      } else {
        state.userScore = payload.response.response.data;
      }

      state.loading = false;
    },
    [handleGetUserScore.pending]: (state) => {
      state.loading = true;
    },
    [handleGetUserScore.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [handleGetSignupInfo.fulfilled]: (state, { payload }) => {
      state.signupInfo = payload?.response?.data?.data;
      state.loading = false;
    },
    [handleGetSignupInfo.pending]: (state) => {
      state.loading = true;
    },
    [handleGetSignupInfo.rejected]: (state) => {
      state.error = true;
    },

    [approveSpecial1Subscription.pending]: (state) => {
      state.loading = true;
    },
    [approveSpecial1Subscription.fulfilled]: (state, { payload }) => {
      state.approvedSpecial1 = payload;
      state.loading = false;
    },
    [approveSpecial1Subscription.rejected]: (state) => {
      state.error = true;
    },

    [getSpecial1OfferUser.pending]: (state) => {
      state.loading = true;
    },
    [getSpecial1OfferUser.fulfilled]: (state, { payload }) => {
      state.approvedUser = payload;
      state.loading = false;
    },
    [getSpecial1OfferUser.rejected]: (state) => {
      state.error = true;
    },

    [getStudentsListSpecial1.pending]: (state) => {
      state.loading = true;
    },
    [getStudentsListSpecial1.fulfilled]: (state, { payload }) => {
      state.special1OfferList = payload?.response?.data;
      state.loading = false;
    },
    [getStudentsListSpecial1.rejected]: (state) => {
      state.error = true;
    },

    [getStudentsListSpecial1Pending.pending]: (state) => {
      state.loading = true;
    },
    [getStudentsListSpecial1Pending.fulfilled]: (state, { payload }) => {
      state.special1OfferList = payload?.response?.data;
      state.loading = false;
    },
    [getStudentsListSpecial1Pending.rejected]: (state) => {
      state.error = true;
    },

    [approveDeySpecial1Offer.pending]: (state) => {
      state.loading = true;
    },
    [approveDeySpecial1Offer.fulfilled]: (state, { payload }) => {
      state.special1OfferUpdateUser = payload;
      state.loading = false;
    },
    [approveDeySpecial1Offer.rejected]: (state) => {
      state.error = true;
    },

    [clearApproveUser.pending]: (state) => {
      state.loading = true;
    },
    [clearApproveUser.fulfilled]: (state, { payload }) => {
      state.approvedUser = {};
      state.loading = false;
    },
    [clearApproveUser.rejected]: (state) => {
      state.error = true;
    },

    [adminResetCounts.pending]: (state) => {
      state.loading = true;
    },
    [adminResetCounts.fulfilled]: (state, { payload }) => {
      state.adminresCounts = {};
      state.loading = false;
    },
    [adminResetCounts.rejected]: (state) => {
      state.error = true;
    },

    [getFlaggedQuestions.pending]: (state) => {
      state.loading = true;
      state.loadingForFlagList = true;
    },
    [getFlaggedQuestions.fulfilled]: (state, { payload }) => {
      state.flaggedQuestionsData = payload?.response?.data;
      state.loading = false;
      state.loadingForFlagList = false;
    },
    [getFlaggedQuestions.rejected]: (state) => {
      state.error = true;
    },

    [getFlagQuestion.pending]: (state) => {
      state.loading = true;
      state.loadingForFlagList = true;
    },
    [getFlagQuestion.fulfilled]: (state, { payload }) => {
      state.flagQuestion = payload?.response?.data;
      state.loading = false;
      state.loadingForFlagList = false;
    },
    [getFlagQuestion.rejected]: (state) => {
      state.error = true;
    },
    [sendAdminEmail.pending]: (state) => {
      state.loading = true;
    },
    [sendAdminEmail.fulfilled]: (state, { payload }) => {
      state.adminEmailData = payload?.response?.data;
      state.loading = false;
    },
    [sendAdminEmail.rejected]: (state) => {
      state.error = true;
    },
  },
});

// export const { logout } = adminSlice.actions; extra reducers actions

export default adminSlice.reducer;
