import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSpeechSynthesis } from 'react-speech-kit';
import { Container, Accordion, Button } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { isEmpty, has, isNull, isArray } from 'lodash';
import { useFormik, Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import TextAnswerQ from '../../../components/QuestionsGroup/TextAnswerQ';
import DictationQ from '../../../components/QuestionsGroup/DictationQ';
import MathExpressionQ from '../../../components/QuestionsGroup/MathExpressionQ';
import MultipleChoiceQ from '../../../components/QuestionsGroup/MultipleChoiceQ';
import UniqueChoiceQ from '../../../components/QuestionsGroup/UniqueChoiceQ';
import TrueFalseQ from '../../../components/QuestionsGroup/TrueFalseQ';
import FillGroupQ from '../../../components/QuestionsGroup/FillGroupQ';
import FillInlineQ from '../../../components/QuestionsGroup/FillInlineQ';
import FillBlankQ from '../../../components/QuestionsGroup/FillBlankQ';
import FillImgQ from '../../../components/QuestionsGroup/FillImgQ';
import MatchItemsQ from '../../../components/QuestionsGroup/MatchItemsQ';
import AccordionItem from '../../../components/PracticeSectionComponents/AccordionItem';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import HomeIcon from '../../../assets/images/icons/home-icon.svg';
import breadcrumbArrow from '../../../assets/images/icons/breadcrumb-arrow.svg';
import {
	handleGetQuestionSets,
	handlePracticeSectionsSubmit
} from '../../../redux/practice';
import { resetFormData } from '../../../redux/practice/slice';
import { getFieldVal } from '../../../utils/practice';
import './styles.scss';
import Header from '../../../components/Header';
import CustomModal from '../../../components/CustomModal';

const SectionsPage = () => {
	const shouldRender = useRef(true);
	const dispatch = useDispatch();
	const location = useLocation();
	const [id, setId] = useState(null);
	const [loadingForResponse, setLoadingForResponse] = useState(true);
	const [currentActiveAccordion, setCurrentActiveAccordion] = useState('1');
	const [readMoreModal, setReadMoreModal] = useState(false);
	const qSetId = new URLSearchParams(location.search).get('id');

	const { loading, questionsList, activeAccordion, practiceSubmitResponse } =
		useSelector((state) => state.practice);

	useEffect(() => {
		if (shouldRender.current) {
			shouldRender.current = false;
			const promise = dispatch(handleGetQuestionSets(qSetId));
			return () => {
				promise.abort();
			};
		}
	}, []);

	useEffect(() => {
		if (practiceSubmitResponse && practiceSubmitResponse.data) {
			const promise = dispatch(handleGetQuestionSets(qSetId));
			return () => {
				promise.abort();
			};
		}
	}, [practiceSubmitResponse]);

	useEffect(() => {
		return () => {
			dispatch(resetFormData());
		};
	}, []);

	// Success and error message handle
	useEffect(() => {
		if (isCall) {
			errorMsg();
		}
	}, [practiceSubmitResponse]);

	const [isCall, setIsCall] = useState(false);
	const errorMsg = () => {
		if (practiceSubmitResponse && practiceSubmitResponse.error) {
			setIsCall(false);
			toast.error(practiceSubmitResponse.message);
		} else if (practiceSubmitResponse && practiceSubmitResponse.data) {
			setIsCall(false);
			toast.success(practiceSubmitResponse?.message);
		} else {
			setIsCall(false);
		}
	};

	const onSubmit = (values) => {
		for (let i = 0; i < values.list_sections.length; i++) {
			const arr = Object.values(values.list_sections[i].dict_qno_attempted);
			values.list_sections[i].noq_attempted = 0;
			values.list_sections[i].noq_total = arr.length;
			for (let j = 0; j < arr.length; j++) {
				if (arr[j] === true) {
					values.list_sections[i].noq_attempted += 1;
				}
			}
		}
		dispatch(handlePracticeSectionsSubmit(values));
		setIsCall(true);
	};

	const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
		enableReinitialize: true,
		initialValues: {},
		onSubmit
	});

	useEffect(() => {
		if (questionsList && questionsList.error && isEmpty(questionsList)) {
			setLoadingForResponse(false);
		}

		if (isEmpty(questionsList)) return;

		setFieldValue('qset_id', `${questionsList.set_id}`);
		setFieldValue('clicked_submit', null);

		for (let i = 0; i < questionsList.list_sections.length; i++) {
			const noqAttempted = questionsList.list_sections[i].noq_attempted;
			const noqTotal = questionsList.list_sections[i].noq_total;

			setFieldValue(`list_sections.${i}.noq_attempted`, 0);

			if (noqAttempted) {
				setFieldValue(`list_sections.${i}.noq_attempted`, noqAttempted);
			}
			if (noqTotal) {
				setFieldValue(`list_sections.${i}.noq_total`, noqTotal);
			}

			for (let j = 0; j < questionsList.list_sections[i].list_qgs.length; j++) {
				for (
					let k = 0;
					k < questionsList.list_sections[i].list_qgs[j].list_ques.length;
					k++
				) {
					const qno = questionsList.list_sections[i].list_qgs[j].list_ques[k].qno;
					const dictQnoAttempted = questionsList.list_sections[i].dict_qno_attempted;
					const listQues = questionsList.list_sections[i].list_qgs[j].list_ques[k];

					setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, false);

					// for questions after save as draft and submit
					if (
						(dictQnoAttempted && dictQnoAttempted[qno]) ||
						(has(listQues, 'attempted_obj') && !isNull(listQues.attempted_obj))
					) {
						setFieldValue(`list_sections.${i}.dict_qno_attempted.${qno}`, true);
					}

					setFieldValue(`list_sections.${i}.list_ags.${j}.list_ans.${k}.qno`, qno);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
						null
					);

					// for submitted Question
					if (has(listQues, 'attempted_obj')) {
						setFieldValue(
							`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
							listQues.attempted_obj
						);
					}
					// for Questions after save as a draft
					if (questionsList.draft && questionsList.draft.attempted) {
						setFieldValue(
							`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
							listQues.draft.stud_ans
						);
					}
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.q_type`,
						listQues.q_type
					);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.list_times`,
						[]
					);
					setFieldValue(
						`list_sections.${i}.list_ags.${j}.list_ans.${k}.q_id`,
						listQues.q_id
					);
				}
			}
		}
		setLoadingForResponse(false);
	}, [questionsList]);

	const onEnd = () => {
		setId(null);
	};

	const { speak } = useSpeechSynthesis({ onEnd });

	const handleClick = (qType, questions, i, j, k) => {
		const changeClass = () => {
			speak({ text: questions.q_html });
			setId(k);
		};
		const active = () => {
			if (id === k) {
				return true;
			}
			return false;
		};
		const props = {};
		props.key = k;
		props.data = questions;
		props.indexValue = { i, j, k };
		props.active = active();
		props.onPress = changeClass;
		props.value = !isEmpty(values) && values;
		props.onChange = handleChange;
		props.setFieldVal = setFieldValue;
		props.fieldValue = getFieldVal(values, i, j, k);
		props.name = `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`;

		switch (qType) {
			case 'TextAnswerQ':
				return <TextAnswerQ {...props} />;
			case 'DictationQ':
				return <DictationQ {...props} />;
			case 'MathExpressionQ':
				return <MathExpressionQ {...props} />;
			case 'MultipleChoiceQ':
				return <MultipleChoiceQ {...props} />;
			case 'UniqueChoiceQ':
				return <UniqueChoiceQ {...props} />;
			case 'TrueFalseQ':
				return <TrueFalseQ {...props} />;
			case 'FillImgQ':
				return <FillImgQ {...props} />;
			case 'FillBlankQ':
				return <FillBlankQ {...props} />;
			case 'FillGroupQ':
				return <FillGroupQ {...props} />;
			case 'FillInlineQ':
				return <FillInlineQ {...props} />;
			case 'MatchItemsQ':
				return <MatchItemsQ {...props} />;
			default:
				return '';
		}
	};

	const navigateToQuestion = (eve, e, section) => {
		if (currentActiveAccordion === section.sno) {
			eve.stopPropagation();
		}
		const ele = document.getElementById(`${e.q_type}_${e.qno}`);
		setTimeout(() => {
			window.scrollTo({
				top: ele.offsetTop - 250,
				behavior: 'smooth'
			});
		}, 700);
	};

	const handleAttempted = (e, index) => {
		if (e.ans_obj && e.ans_obj.correctness === 'right') {
			return 'right';
		} else if (e.ans_obj && e.ans_obj.correctness === 'wrong') {
			return 'wrong';
		} else if (e.attempted_obj && isArray(e.ans_obj.ugot_right)) {
			if (e.ans_obj.ugot_right.every((e) => e === true)) {
				return 'right';
			} else {
				return 'wrong';
			}
		} else if (
			!isEmpty(values) &&
			!isEmpty(values.list_sections) &&
			!isEmpty(values.list_sections[index]) &&
			!isEmpty(values.list_sections[index].dict_qno_attempted) &&
			values.list_sections[index].dict_qno_attempted[e.qno] === true
		) {
			return 'question_attempted';
		} else {
			return '';
		}
	};

	const isQuestionAttempted = () => {
		if (!questionsList?.submitted?.attempted) return false;

		return true;
	};

	const setActiveAccordion = (e) => {
		if (isNull(e)) setCurrentActiveAccordion(null);
		setCurrentActiveAccordion(e);
	};

	const readMoreCloseModal = () => setReadMoreModal(false);
	const readMoreOpenModal = () => setReadMoreModal(true);

	const SectionMainPage = () => {
		if (loading || loadingForResponse) {
			return <div className='loader_section'>Loading...</div>;
		}

		if (questionsList && questionsList.error) {
			return <ErrorHandleComponent errorMessage={questionsList.message} />;
		}

		const modalTitle = 'Description';
		const modalContent = (
			<div
				dangerouslySetInnerHTML={{ __html: questionsList?.set_description }}
			></div>
		);
		const modalFooterButtons = [
			{ label: 'Close', variant: 'danger', onClick: readMoreCloseModal }
		];

		return (
			<div>
				<Header />
				<ToastContainer />
				<section className='banner_section'>
					<Container>
						{!isEmpty(questionsList) && <h1>{questionsList.set_name}</h1>}
						{!isEmpty(questionsList) && !isEmpty(questionsList.set_description) && (
							<>
								<p>
									{questionsList.set_description?.substring(0, 60) + '...'}
									{questionsList.set_description?.length > 60 && (
										<Button onClick={() => readMoreOpenModal(true)}>read more</Button>
									)}
								</p>
							</>
						)}
					</Container>
					<CustomModal
						title={modalTitle}
						content={modalContent}
						footerButtons={modalFooterButtons}
						show={readMoreModal}
						handleClose={readMoreCloseModal}
					/>
				</section>
				<section className='breadcrumbs'>
					<Container>
						<div className='breadcrumbs_wrapper'>
							<ul>
								<li>
									<ReactSVG src={HomeIcon} className='icon' />
									<Link to={'/practice'}>Practice</Link>
								</li>
								<li className='active'>
									<ReactSVG src={breadcrumbArrow} className='icon' />
									{!isEmpty(questionsList) && questionsList.set_name}
								</li>
							</ul>
							{isQuestionAttempted() && (
								<p className='total_score'>
									Total Score: {questionsList?.submitted?.data?.total_score_pct} %
								</p>
							)}
						</div>
					</Container>
				</section>
				<section className='sections_list'>
					<Container>
						<Formik>
							<Form>
								<Accordion
									defaultActiveKey={!isNull(activeAccordion) ? activeAccordion : '1'}
									onSelect={(e) => setActiveAccordion(e)}
								>
									{!isEmpty(questionsList) &&
										questionsList.list_sections.map((section, i) => (
											<AccordionItem
												key={section.sno}
												section={section}
												index={i}
												handleAttempted={handleAttempted}
												navigateToQuestion={navigateToQuestion}
												data={questionsList}
												currentActiveAccordion={currentActiveAccordion}
												handleClick={handleClick}
											/>
										))}
								</Accordion>
								{questionsList && !questionsList.timed && !isQuestionAttempted() && (
									<Button
										type='submit'
										onClick={handleSubmit}
										className='grad_submit_btn'
									>
										<div>
											<h5>Submit for grading</h5>
										</div>
									</Button>
								)}
								{isQuestionAttempted() && (
									<p className='after_submit_note'>This set is already graded.</p>
								)}
							</Form>
						</Formik>
					</Container>
				</section>
			</div>
		);
	};

	return <div className='sections_page'>{SectionMainPage()}</div>;
};

export default SectionsPage;
