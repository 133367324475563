import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import adminReducer from './admin/slice';
import practiceReducer from './practice/slice';

const reducers = combineReducers({
	auth: authReducer,
	admin: adminReducer,
	practice: practiceReducer
});
const rootReducer = (state, action) => reducers(state, action);
const persistConfig = {
	key: 'root',
	storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});
export const persistor = persistStore(store);
