import React from 'react';
import Countdown from 'react-countdown';
import './styles.scss';

const Timer = ({ handleSubmit }) => {
	const renderer = ({ hours, minutes, seconds }) => {
		return (
			<span>
				{hours}:{minutes}:{seconds}
			</span>
		);
	};

	const handleClass = () => {
		if (
			(parseInt(localStorage.getItem('time')) - Date.now()) < 60000) {
			return 'danger_time';
		}
		return '';
	};

	if (!localStorage.getItem('time')) return;

	return (
		<div className={`timer ${handleClass()}`}>
			<div>
				<Countdown
					date={
						parseInt(localStorage.getItem('time'))
					}
					renderer={renderer}
					onComplete={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default Timer;
