import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const ChartComponent = ({ userLastWeekScore, loading }) => {
	const [scoreData, setScoreData] = useState([]);

	useEffect(() => {
		if (!isEmpty(userLastWeekScore)) {
			const colordata = userLastWeekScore.score.map((e, i) => {
				const color = randomColor(i);
				return {
					data: e.data,
					label: e.label,
					borderColor: color,
					backgroundColor: color
				};
			});
			setScoreData(colordata);
		}
	}, [userLastWeekScore]);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top'
			}
		},
		scales: {
			x: {
				title: {
					display: true
				}
			},
			y: {
				min: 0,
				max: 100,
				title: {
					display: true,
					text: '% Score'
				},
				ticks: {
					stepSize: 10
				}
			}
		}
	};

	const color = [
		'red',
		'blue',
		'green',
		'yellow',
		'lightgrey',
		'orange',
		'black',
		'lightblue',
		'pink',
		'cyan'
	];

	const randomColor = (i) => {
		return color[i];
	};

	const data = {
		labels: !isEmpty(userLastWeekScore) && userLastWeekScore.dates.map((e) => e),
		datasets: scoreData
	};

	if (loading) {
		return <div className='loader_section'>Loading...</div>;
	}

	return <Line options={options} data={data} />;
};
export default ChartComponent;
