import { createSlice } from '@reduxjs/toolkit';
import {
	handleGetPracticeList,
	handleGetQuestionSets,
	handlePracticeSectionsSubmit,
	handleImportPractice,
	handleWebImportPractice,
	handleCounterGet,
	handleCounterPost,
	timedSaveAsDraft,
	getTimedSaveAsDraftData,
	handleTimedSetSubmission
} from '../practice';

const initialState = {
	activeAccordion: null,
	error: false,
	practiceList: null,
	questionsList: null,
	loading: false,
	answerSubmitted: false,
	practiceSubmitResponse: null,
	importPracticeResponse: null,
	importWebPracticeResponse: null,
	counterResponse: null,
	counterPostResponse: null,
	timedSaveAsDraftResponse: null,
	getTimedSaveAsDraftDataResponse: null,
	timedSectionLoading: false,
	timedSetSubmissionResponse: null
};

const practiceSlice = createSlice({
	name: 'practice',
	initialState,
	reducers: {
		currentAccordian: (state, { payload }) => {
			state.activeAccordion = payload;
		},
		resetFormData: (state) => {
			state.questionsList = null;
			state.answerSubmitted = false;
			state.counterResponse = null;
			state.counterPostResponse = null;
			state.timedSaveAsDraftResponse = null;
			state.getTimedSaveAsDraftDataResponse = null;
		}
	},
	extraReducers: {
		[handleGetPracticeList.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.practiceList = payload.response.data.data;
			}
			if (payload.response.response) {
				state.practiceList = payload.response.response.data;
			}
			state.loading = false;
		},
		[handleGetPracticeList.pending]: (state) => {
			state.loading = true;
		},
		[handleGetPracticeList.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},

		[handleGetQuestionSets.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.questionsList = payload.response.data.data;
			}
			if (payload.response.response) {
				state.questionsList = payload.response.response.data;
			}
			state.loading = false;
		},
		[handleGetQuestionSets.pending]: (state) => {
			state.loading = true;
		},
		[handleGetQuestionSets.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},

		[handlePracticeSectionsSubmit.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.practiceSubmitResponse = payload.response.data;
				state.error = false;
				state.answerSubmitted = true;
			}
			if (payload.response.response) {
				state.practiceSubmitResponse = payload.response.response.data;
				state.error = true;
				state.answerSubmitted = false;
			}
			state.loading = false;
		},
		[handlePracticeSectionsSubmit.pending]: (state) => {
			state.loading = true;
			state.answerSubmitted = false;
		},
		[handlePracticeSectionsSubmit.rejected]: (state) => {
			state.error = true;
			state.loading = false;
			state.answerSubmitted = false;
		},

		[handleImportPractice.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.importPracticeResponse = payload.response.data;
				state.error = false;
			}
			if (payload.response.response) {
				state.importPracticeResponse = payload.response.response.data;
				state.error = true;
			}
			state.loading = false;
		},
		[handleImportPractice.pending]: (state) => {
			state.loading = true;
		},
		[handleImportPractice.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},

		[handleWebImportPractice.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.importWebPracticeResponse = payload.response.data;
				state.error = false;
			}
			if (payload.response.response) {
				state.importWebPracticeResponse = payload.response.response.data;
				state.error = true;
			}
			state.loading = false;
		},
		[handleWebImportPractice.pending]: (state) => {
			state.loading = true;
		},
		[handleWebImportPractice.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},
		[handleTimedSetSubmission.fulfilled]: (state, { payload }) => {
			if (payload.response.status === 200) {
				state.timedSetSubmissionResponse = payload.response.data;
				state.error = false;
			}
			if (payload.response.response) {
				state.timedSetSubmissionResponse = payload.response.response.data;
				state.error = true;
			}
			state.loading = false;
		},
		[handleTimedSetSubmission.pending]: (state) => {
			state.loading = true;
		},
		[handleTimedSetSubmission.rejected]: (state) => {
			state.error = true;
			state.loading = false;
		},
		[handleCounterGet.fulfilled]: (state, { payload }) => {
			state.counterResponse = payload.response.data;
			state.error = false;
			state.timedSectionLoading = false;
		},
		[handleCounterGet.pending]: (state) => {
			state.timedSectionLoading = true;
		},
		[handleCounterGet.rejected]: (state) => {
			state.error = true;
			state.timedSectionLoading = false;
		},
		[handleCounterPost.fulfilled]: (state, { payload }) => {
			state.counterPostResponse = payload.response.data;
			state.error = false;
			state.timedSectionLoading = false;
		},
		[handleCounterPost.pending]: (state) => {
			state.timedSectionLoading = true;
		},
		[handleCounterPost.rejected]: (state) => {
			state.error = true;
			state.timedSectionLoading = false;
		},
		[timedSaveAsDraft.fulfilled]: (state, { payload }) => {
			state.timedSaveAsDraftResponse = payload.response.data;
			state.error = false;
			state.timedSectionLoading = false;
		},
		[timedSaveAsDraft.pending]: (state) => {
			state.timedSectionLoading = true;
		},
		[timedSaveAsDraft.rejected]: (state) => {
			state.error = true;
			state.timedSectionLoading = false;
		},
		[getTimedSaveAsDraftData.fulfilled]: (state, { payload }) => {
			state.getTimedSaveAsDraftDataResponse = payload.response.data;
			state.error = false;
			state.timedSectionLoading = false;
		},
		[getTimedSaveAsDraftData.pending]: (state) => {
			state.timedSectionLoading = true;
		},
		[getTimedSaveAsDraftData.rejected]: (state) => {
			state.error = true;
			state.timedSectionLoading = false;
		}
	}
});

export const { currentAccordian, resetFormData } = practiceSlice.actions;

export default practiceSlice.reducer;
