/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { isNull } from "lodash";
import QuestionHintComponent from "../QuestionHintComponent";
import QueExplanationComponent from "../QueExplanationComponent";
import QuestionScoreComponent from "../QuestionScoreComponent";
import {
  questionCorrectnessType2,
  attemptedQuestionCounterType4,
} from "../../../utils/practice";
import "./styles.scss";

const TrueFalseQ = ({
  data,
  indexValue,
  value,
  setFieldVal,
  fieldValue,
  name,
}) => {
  const [answer, setAnswer] = useState(fieldValue);
  const [active, setActive] = useState([false, false]);
  const [id, setId] = useState(
    fieldValue === true ? 0 : fieldValue === false ? 1 : null
  );
  const { i, j, k } = indexValue;
  const tfqOptions = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  useEffect(() => {
    attemptedQuestionCounterType4(data, value, indexValue, fieldValue);
  }, [value]);

  useEffect(() => {
    if ((data.ans_obj || data.draft) && !isNull(fieldValue)) {
      const tempActiveArray = active;
      const submittedAns = fieldValue;
      if (submittedAns === true) {
        tempActiveArray[0] = true;
      } else if (submittedAns === false) {
        tempActiveArray[1] = true;
      }
    }
  }, []);

  const handleChange = (e) => {
    if (isNull(answer) || e.target.value !== answer) {
      setFieldVal(
        `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
        e.target.value === "true"
      );
    }
  };

  const handleClick = (e, index) => {
    if (
      active[index] === false &&
      (isNull(answer) || e.target.value !== answer)
    ) {
      setAnswer(e.target.value);
      setId(index);
      active[index] = true;
      active[id] = false;
      setActive(active);
    } else {
      setFieldVal(
        `list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans`,
        null
      );
      setAnswer(null);
      active[index] = false;
      setActive(active);
      setId(null);
    }
  };

  return (
    <div className="tfq_simple_display" id={`${data.q_type}_${data.qno}`}>
      <div className="tfq_simple_display_header">
        <div className="question_number">
          <h6>{data.qno}</h6>
          <QuestionScoreComponent data={data} />
          <QueExplanationComponent
            data={data}
            explanations={data.explanations}
          />
        </div>
        <div className="content">
          <h4 dangerouslySetInnerHTML={{ __html: data.q_html }}></h4>
          <div className="icons">
            <QuestionHintComponent hints={data.hints} />
          </div>
        </div>
      </div>
      <div className="tfq_simple_display_radio">
        {tfqOptions.map((element, index) => {
          return (
            <Form.Check key={index}>
              <Form.Check.Input
                type="radio"
                name={name}
                onChange={(e) => handleChange(e, index)}
                onClick={(e) => handleClick(e, index)}
                checked={fieldValue === element.value && true}
                value={element.value}
                disabled={data.ans_obj}
              />
              <Form.Check.Label
                className={`${fieldValue === element.value && "active"} ${
                  data.ans_obj && questionCorrectnessType2(data, element.value)
                }`}
              >
                {element.label}
              </Form.Check.Label>
            </Form.Check>
          );
        })}
      </div>
    </div>
  );
};

export default TrueFalseQ;
