import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleGetPaidStudent } from '../../../redux/admin';
import {
	Container,
	Row,
	Button,
	Form,
	Table,
	Col,
	Pagination
} from 'react-bootstrap';
import './style.scss';

const PaidStudents = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { paidStudents } = useSelector((state) => state.admin);
	const { totalData } = useSelector((state) => state.admin);
	useEffect(() => {
		dispatch(handleGetPaidStudent({}));
	}, []);

	const columns = [
		{ id: 'id', label: 'StudenID' },
		{ id: 'name', label: 'Name', minWidth: 150 },
		{
			id: 'email',
			label: 'Email',
			minWidth: 80,
			align: 'left',
			format: (value) => value.toFixed(2)
		}
	];

	function createData (id, name, email, edit) {
		return { id, name, email, edit };
	}
	const rows = [
		paidStudents &&
			paidStudents.data.map((item) => {
				return createData(item.id, item.username, item.email);
			})
	];

	// Pagination
	const [page, setPage] = useState(1);
	const handlePageChange = (event, value) => {
		dispatch(handleGetPaidStudent({ page: value, search: searchInput }));
		setPage(value);
	};

	// Search
	const [searchInput, setSearchInput] = useState('');
	const handleSearch = (event) => {
		if (event.target.value === '') {
			dispatch(handleGetPaidStudent({ page }));
			setSearchInput(event.target.value);
			return;
		}
		setSearchInput(event.target.value);
		dispatch(handleGetPaidStudent({ search: event.target.value }));
	};
	return (
		<div className='paid_students_wrapper'>
			<Container>
				<Row>
					<h3>Paid_Student</h3>
					<Row className='mt-3 mb-3'>
						<Button
							onClick={() => history.back()}
							size='sm'
							variant='outline-dark'
							className='w-auto student_back_btn'
						>
							Back
						</Button>
						<Form.Control
							type='text'
							placeholder='Search'
							id='search'
							onChange={handleSearch}
							className='search_input'
						/>
					</Row>

					{/* <TextField
						sx={{ m: 2 }}
						id="search"
						label="Search"
						variant="outlined"
						size="small"
						onChange={ handleSearch }
					/> */}

					<Table>
						<tbody>
							<tr>
								{columns.map((column) => (
									<th
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										<p>{column.label}</p>
									</th>
								))}
								<th>
									<p>Details</p>
								</th>
							</tr>
							{rows[0] &&
								rows[0].map((row) => {
									return (
										<tr
											role='checkbox'
											tabIndex={-1}
											key={row.id}
											style={{ cursor: 'pointer' }}
										>
											{columns.map((column) => {
												const value = row[column.id];
												return (
													<td key={column.id}>
														{column.format && typeof value === 'number'
															? column.format(value)
															: value}
													</td>
												);
											})}
											<td>
												<Button
													onClick={(event) => {
														event.stopPropagation();
														navigate(`/user-details/${row.id}`, { state: row });
													}}
												>
													Profile
												</Button>
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
					{rows[0] && rows[0].length === 0
						? (
							<>
								<Row>
									<Col xs={12}>
										<p className='text-center mb-0'>Data Not Found</p>
									</Col>
								</Row>
							</>
						)
						: (
							<Row>
								<Pagination
									showFirstButton
									showLastButton
									size='large'
									count={Math.ceil(totalData && totalData / 10)}
									page={page}
									onChange={handlePageChange}
									shape='rounded'
									variant='outlined'
								/>
							</Row>
						)}
				</Row>
			</Container>
		</div>
	);
};

export default PaidStudents;
