/* eslint-disable */
import React, { memo } from "react";
import { isArray } from "lodash";
import "./styles.scss";

const RightAnswereComponent = ({ data, index }) => {
  if (!data?.ans_obj) {
    return null;
  }
  const gotRight = data.ans_obj.ugot_right;
  if (gotRight === true || (Array.isArray(gotRight) && gotRight[index])) {
    return null;
  }

  if (isArray(data.ans_obj.ans_key)) {
    return <p className="answere">{data.ans_obj.ans_key[index]}</p>;
  }

  return <p className="answere">{data.ans_obj.ans_key}</p>;
};

export default memo(RightAnswereComponent);
