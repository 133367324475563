import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import QuestionHeadComponent from '../QuestionHeadComponent';
import QuestionScoreComponent from '../QuestionScoreComponent';
import {
	mcqQuestionCorrectness,
	inputValueType3,
	attemptedQuestionCounterType2,
	correctQuestionValType2
} from '../../../utils/practice';
import './styles.scss';
import Markdown from '../../Markdown';

const MultipleChoiceQ = ({
	data,
	onChange,
	value,
	indexValue,
	fieldValue,
	setFieldVal,
	name
}) => {
	const { i, j, k } = indexValue;

	useEffect(() => {
		attemptedQuestionCounterType2(data.qno, data.list_chtml, value, indexValue);
		correctQuestionValType2(data, fieldValue, setFieldVal, name);
	}, [value]);

	const generateHTMLContent = (ctext) => {
		return <Markdown>{ctext}</Markdown>;
	};

	return (
		<div className='mcq_simple_display' id={`${data.q_type}_${data.qno}`}>
			<div className='mcq_simple_display_header'>
				<div className='question_number'>
					<h6>{data.qno}</h6>
					<QuestionScoreComponent data={data} />
				</div>
			</div>
			<div className='mcq_simple_display_content'>
				{/* <QuestionHeadComponent
					data={data}
					questionData={data.q_html}
					questionHintData={data.hints}
					questionType={data.q_type}
				/> */}
				<QuestionHeadComponent
					data={data}
					questionData={data.q_text}
					questionHintData={data.hints}
					headText={data.q_html}
					questionType={data.q_type}
				/>
				<div>
					<ol
						type='A'
						className={`${data.show_as_ABCD ? '' : 'remove_abcd'} ${
							data.align_h ? 'align_h' : ''
						}`}
					>
						{data.list_chtml.map((ctext, index) => {
							return (
								<div key={index} className='item'>
									<li>
										<Form.Check inline={true}>
											<Form.Check.Input
												key={index}
												type='checkbox'
												id={`default-${index}`}
												name=
													{`list_sections.${i}.list_ags.${j}.list_ans.${k}.stud_ans.${index}`}
												onChange={onChange}
												value={inputValueType3(data, value, indexValue, index)}
												checked={inputValueType3(data, value, indexValue, index)}
												disabled={data.ans_obj}
												className={`${
													data.ans_obj && data.ans_obj.ans_key[index] === false && 'wrong'
												} ${
													data.ans_obj && data.ans_obj.ans_key[index] === true && 'right'
												}`}
											/>
											<Form.Check.Label
												htmlFor={`default-${index}`}
												className={data.ans_obj && mcqQuestionCorrectness(data, index)}
												data-index={String.fromCharCode(65 + index)}
											>
												{/* <span dangerouslySetInnerHTML={{ __html: ctext }}></span> */}
												{generateHTMLContent(ctext)}
											</Form.Check.Label>
										</Form.Check>
									</li>
								</div>
							);
						})}
					</ol>
				</div>
			</div>
		</div>
	);
};

export default MultipleChoiceQ;
