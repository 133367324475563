import React from 'react';
// import { ThemeProvider } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
// import base from './themes/base';
// import theme from './styles/theme';
// import GlobalStyles from './themes/globalStyles';
import RouterContainer from './router';
import './App.css';
import './locales/i18n';
import './theme.scss';

function App () {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{/* <GlobalStyles /> */}
				{/* <ThemeProvider theme={theme}> */}
				<ToastContainer />
				<RouterContainer />
				{/* </ThemeProvider> */}
			</PersistGate>
		</Provider>
	);
}

export default App;
