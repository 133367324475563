import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/slice';

const ServerErrorPage = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(logout());
	}, []);

	return (
		<div>Internal server error</div>
	);
};

export default ServerErrorPage;
