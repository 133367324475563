/* eslint-disable */
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";
import { PublicRoute } from "./publicRoute";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import Dashboard from "../pages/dashboard";
import Students from "../pages/Admin/Students";
import PaidStudents from "../pages/Admin/PaidStudent";
import UserDetails from "../pages/Admin/UserDetails";
import ChangePassword from "../pages/auth/changePassword";
import UserDashBoard from "../pages/Admin/UserDashBoard";
import ServerErrorPage from "../pages/ServerErrorPage";
import NotFoundPage from "../pages/NotFoundPage";
import PracticePage from "../pages/Practice/PracticePage";
import SectionsPage from "../pages/Practice/SectionPage";
import TimedSectionPage from "../pages/Practice/TimedSectionPage";
import ControlPage from "../pages/controls";
import Special1OfferStudents from "../pages/Admin/special1Offer";
import FlaggedQuestions from "../pages/Admin/FlaggedQuestions";
import AdminEmail from "../pages/Admin/AdminEmail";

const LoadPublicRouteComponent = ({ component }) => (
  <PublicRoute>{component}</PublicRoute>
);
const LoadProtectedRouteComponent = ({ component }) => (
  <ProtectedRoute>{component}</ProtectedRoute>
);

const RouterContainer = () => (
  <BrowserRouter>
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/login"
          element={<LoadPublicRouteComponent component={<Login />} />}
        />
        <Route path="/500" element={<ServerErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/change-password"
          element={
            <LoadProtectedRouteComponent component={<ChangePassword />} />
          }
        />
        <Route
          path="/signup"
          element={<LoadPublicRouteComponent component={<SignUp />} />}
        />
        <Route
          path="/practice"
          element={<LoadProtectedRouteComponent component={<PracticePage />} />}
        />
        <Route
          path="/practice/:slug"
          element={<LoadProtectedRouteComponent component={<SectionsPage />} />}
        />
        <Route
          path="/practice/timed/:slug"
          element={
            <LoadProtectedRouteComponent component={<TimedSectionPage />} />
          }
        />
        <Route
          path="/student-list"
          element={<LoadProtectedRouteComponent component={<Students />} />}
        />
        <Route
          path="/user-details/:id"
          element={<LoadProtectedRouteComponent component={<UserDetails />} />}
        />
        <Route
          path="/user-dashboard/:id"
          element={
            <LoadProtectedRouteComponent component={<UserDashBoard />} />
          }
        />
        <Route
          path="/paid-student"
          element={<LoadProtectedRouteComponent component={<PaidStudents />} />}
        />
        <Route
          path="/controls"
          element={<LoadProtectedRouteComponent component={<ControlPage />} />}
        />
        <Route
          path="/controls/special1-requests"
          element={
            <LoadProtectedRouteComponent
              component={<Special1OfferStudents />}
            />
          }
        />
        <Route
          path="/controls/flagged-questions"
          element={
            <LoadProtectedRouteComponent component={<FlaggedQuestions />} />
          }
        />
        <Route
          path="/controls/admin-email"
          element={<LoadProtectedRouteComponent component={<AdminEmail />} />}
        />
        <Route
          path="/"
          element={<LoadProtectedRouteComponent component={<Dashboard />} />}
        />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default RouterContainer;
