import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
	handleGetPracticeList,
	handleImportPractice
} from '../../../redux/practice';
import QuestionSetContainer from '../../../components/PracticeSetComponents/QuestionSetContainer';
import CustomModal from '../../../components/CustomModal';
import './styles.scss';
import Header from '../../../components/Header';

const PracticePage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [timeHours, setTimeHours] = useState(0);
	const [timeMin, setTimeMin] = useState(0);
	const [qCount, setQCount] = useState(null);
	const [includeTimed, setIncludeTimed] = useState(true);
	const [mustTimed, setMustTimed] = useState(false);
	const [url, setUrl] = useState('');
	const [questionSetId, setQuestionSetId] = useState('');
	const [practiceUrl, setPracticeUrl] = useState('');
	const [isAdminCall, setIsAdminCall] = useState(false);

	const { loading, practiceList, importPracticeResponse } = useSelector(
		(state) => state.practice
	);

	const handleClick = (
		url,
		setId,
		isTimedQuestion,
		isSubmitted,
		timeHrs,
		timeMins,
		qCount,
		isMustTimed
	) => {
		setQuestionSetId(setId);
		setPracticeUrl(url);
		setMustTimed(isMustTimed);
		if (isTimedQuestion) {
			if (isSubmitted) {
				navigate(`${url}?id=${setId}`);
			} else {
				setShow(true);
				setTimeHours(timeHrs);
				setTimeMin(timeMins);
				setQCount(qCount);
				setUrl(`/practice/timed/setId?id=${setId}`);
			}
		} else {
			navigate(`${url}?id=${setId}`);
		}
	};

	const handleTimedQuestionChange = (e) => {
		setIncludeTimed(e.target.checked);
		if (e.target.checked) {
			setUrl(`/practice/timed/setId?id=${questionSetId}`);
		} else {
			setUrl(`${practiceUrl}?id=${questionSetId}`);
		}
	};

	useEffect(() => {
		if (isAdminCall) {
			if (importPracticeResponse && importPracticeResponse.status_code === 200) {
				setIsAdminCall(false);
				toast.success(importPracticeResponse.message);
				window.location.reload();
			} else if (importPracticeResponse && importPracticeResponse.error) {
				setIsAdminCall(false);
				toast.error(importPracticeResponse.message);
			}
		}
	}, [importPracticeResponse]);

	useEffect(() => {
		dispatch(handleGetPracticeList());
	}, []);

	const timedModalTitle = 'Let\'s get started!';
	// const timedModalContentArray = [
	// 	`Total number of questions: ${qCount}, and total time:
	// ${timeHours ? timeHours + ' hour ' : ''}${timeMin} minutes`,
	// 	'Once the test starts the user must finish it in one sitting'
	// ];
	// const timedModalContent = timedModalContentArray.map((e, i) => (
	// 	<p key={i}>{e}</p>
	// ));
	const timedModalContent = (
		<>
			<div className='timed_modal_info_wrap'>
				<div className='timed_modal_info'>
					<h4>{`${qCount}`}</h4>
					<h5>Questions</h5>
					{includeTimed
						? <h4>{`${timeHours ? timeHours + 'h ' : ''}${timeMin ? timeMin + 'm' : ''}`}</h4>
						: <div className='infinity_time'>
							<h4>&infin;</h4>
							<h5>Complete by Sunday</h5></div>}
				</div>
			</div>
			<div className='timed_modal_rules'>
				{mustTimed
					? <>
						<span>Can't finish in one sitting?</span>
						<div className='switch_checkbox_part'>
							<p>Before starting you can switch:</p>
							<div className='switch_part'>
								<Form.Check
									type="switch"
									id="custom-switch"
									label={includeTimed
										? 'Timed (must complete in one sitting)'
										: 'Not timed (complete by Sunday)'}
									reverse
									value={includeTimed}
									checked={includeTimed}
									onChange={handleTimedQuestionChange}
								/>
							</div>
						</div>
					</>
					: <span>Must complete in one sitting.</span>}
			</div>
		</>
	);

	const closeTimedModal = () => setShow(false);

	const timedModalFooterButtons = [
		// {
		// 	label: 'Start later',
		// 	variant: 'link',
		// 	onClick: closeTimedModal
		// },
		{
			label: 'Start now',
			variant: 'primary',
			onClick: () => navigate(url)
		}
	];

	const PracticeMainPage = () => {
		if (loading) {
			return <div className='loader_section'>Loading...</div>;
		}

		if (practiceList && practiceList.error) {
			return (
				<div>
					<p className='error_message'>
						{practiceList.message || 'Please try after some time'}
					</p>
				</div>
			);
		}

		if (!practiceList) return;

		return (
			<div>
				<Header />
				<section className='banner_section'>
					<Container>
						<div className='banner_wrapper'>
							<div className='banner_content'>
								<h1>
									<span>Practice Sets</span>
								</h1>
							</div>
						</div>
					</Container>
				</section>
				<section className='questions_set_section' id='question-section22'>
					<Container>
						<Button
							onClick={() => {
								dispatch(handleImportPractice());
								setIsAdminCall(true);
							}}
						>
							<div>
								<h5>Get new data for Admin</h5>
							</div>
						</Button>
					</Container>
					<Container>
						{practiceList.data?.map((item, index) => (
							<QuestionSetContainer
								key={index}
								item={item}
								handleClick={handleClick}
							/>
						))}
					</Container>
					<CustomModal
						className={'count_down_time_modal'}
						title={timedModalTitle}
						content={timedModalContent}
						footerButtons={timedModalFooterButtons}
						show={show}
						handleClose={closeTimedModal}
					/>
				</section>
			</div>
		);
	};

	const handlePracticePageClass = () => {
		return 'practice_page';
	};

	return <div className={handlePracticePageClass()}>{PracticeMainPage()}</div>;
};

export default PracticePage;
