/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull, debounce } from "lodash";
import { Form, Button, Container } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader";
import {
  handleGetStudentsList,
  handleGetUserDetails,
} from "../../../redux/admin";
import LineChartIcon from "../../../assets/images/chart.png";
import "./style.scss";
import { MdOutlineEdit } from "react-icons/md";
import UpdatePlan from "./updatePlan";
const Students = () => {
  const [sort, setSort] = useState(null);
  const [sortField, setSortField] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [show, setShow] = useState(false);
  const [rowData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingForStudentList, studentList, totalData, loading, userDetail } =
    useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(handleGetStudentsList({}));
  }, []);

  const handleClickSubscription = (params) => {
    setShow(true);
    setData(params);
    if (["Premium", "premium"].includes(params.subscription_type)) {
      dispatch(handleGetUserDetails(params.userId));
    }
  };

  const columns = [
    {
      id: "id",
      name: "SN",
      width: 100,
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      id: "username",
      name: "Username",
      minWidth: 150,
      selector: (row) => row.username,
      sortable: true,
      sortField: "username",
      cell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                navigate(`/user-details/${params.userId}`, {
                  state: params.userId,
                })
              }
            >
              {params.username}
            </Button>
            <Button
              onClick={() => navigate(`/user-dashboard/${params.userId}`)}
            >
              <img src={LineChartIcon} style={{ width: "12px" }} />
            </Button>
          </>
        );
      },
    },
    {
      id: "first_name",
      name: "F Name",
      minWidth: 150,
      selector: (row) => row.first_name,
      sortable: true,
      sortField: "first_name",
    },
    {
      id: "last_name",
      name: "L Name",
      minWidth: 150,
      selector: (row) => row.last_name,
      sortable: true,
      sortField: "last_name",
    },
    {
      id: "email",
      name: "Email",
      minWidth: 280,
      align: "left",
      selector: (row) => row.email,
      cell: (params) => <a href={`mailto: ${params.email}`}>{params.email}</a>,
      sortable: true,
      sortField: "email",
    },
    {
      id: "subscription_type",
      name: "Subscription Type",
      minWidth: 150,
      selector: (row) => row.subscription_type,
      sortable: true,
      sortField: "subscription_type",
      cell: (params) => {
        return (
          <>
            <MdOutlineEdit
              style={{ cursor: "pointer" }}
              onClick={() => handleClickSubscription(params)}
            />
            <p className="text-capitalize mb-0 ms-1">
              {params.subscription_type}
            </p>
          </>
        );
      },
    },
    {
      id: "date_signed_up",
      name: "Dt Joined",
      minWidth: 110,
      selector: (row) => row.date_signed_up,
      sortable: true,
      sortField: "date_signed_up",
    },
  ];

  function createData(id, name, fname, lname, email, type, joinedDate, userId) {
    return {
      id,
      username: name,
      first_name: fname,
      last_name: lname,
      email,
      subscription_type: type,
      date_signed_up: joinedDate,
      userId,
    };
  }

  const rowsData = [
    studentList &&
      studentList.data.map((item, index) => {
        const from = parseInt(studentList.from);
        return createData(
          from + index,
          item.username,
          item.first_name,
          item.last_name,
          item.email,
          item.subscription_type,
          moment(item.date_signed_up, "ddd, DD MMM YYYY HH:mm:ss z").format(
            "DD MMM YYYY"
          ),
          item.id
        );
      }),
  ];

  const handlePageChange = (value) => {
    setCurrentPage(value);
    dispatch(
      handleGetStudentsList({
        page: value,
        search: searchInput,
        sort: sortField,
        orderBy: sort,
      })
    );
  };

  const handleSortModelChange = (column, sortDirection) => {
    setSort(sortDirection === "asc" ? 1 : -1);
    setSortField(column.sortField);
    dispatch(
      handleGetStudentsList({
        search: searchInput || "",
        sort: column.sortField,
        orderBy: sortDirection === "asc" ? 1 : -1,
      })
    );
  };

  // Search
  const handleSearch = (event) => {
    if (event.target.value === "") {
      dispatch(handleGetStudentsList({}));
      setSearchInput(event.target.value);
      return;
    }
    setSearchInput(event.target.value);
    dispatch(
      handleGetStudentsList({ search: event.target.value ?? searchInput })
    );
  };

  const onSearchDebounce = debounce(handleSearch, 1500);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      !loadingForStudentList && (
        <>
          <Form.Control
            id="search_input"
            type="text"
            onChange={onSearchDebounce}
            defaultValue={searchInput}
            className="search_input"
            placeholder="search"
          />
        </>
      )
    );
  }, [searchInput, loadingForStudentList]);

  if (isNull(rowsData[0])) return;

  return (
    <Container className="fixing-loader">
      <div style={{ width: "100%" }} className="userlist_wrapper">
        <DataTable
          title="Users List"
          columns={columns}
          data={rowsData[0]}
          progressPending={loadingForStudentList}
          progressComponent={<Loader />}
          highlightOnHover
          pagination
          paginationServer
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationTotalRows={totalData}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSort={handleSortModelChange}
          sortServer
        />
      </div>
      <UpdatePlan
        show={show}
        setShow={setShow}
        rowData={rowData}
        searchInput={searchInput}
        currentPage={currentPage}
      />
    </Container>
  );
};

export default Students;
