import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI, userSignUp, userChangePassword } from '../../services/auth';

export const handleLogin = createAsyncThunk(
	'handleLogin',
	async (params, { rejectWithValue }) => {
		const response = await callPostAPI({ route: '/', body: params.body });
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleSignup = createAsyncThunk(
	'handleSignup',
	async (params, { rejectWithValue }) => {
		const response = await userSignUp({ route: '/', body: params.body });
		if (!response) return rejectWithValue();
		return { response };
	}
);

export const handleChangePassword = createAsyncThunk(
	'handleChangePassword',
	async (params, { rejectWithValue }) => {
		const response = await userChangePassword({ route: '/', body: params.body });
		if (!response) return rejectWithValue();
		return { response };
	}
);
