/* eslint-disable */
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Formik, Form, Field } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import {
  approveSpecial1Subscription,
  clearApproveUser,
  getSpecial1OfferUser,
} from "../../../../redux/admin";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.scss";

const UpdatePlan = ({ show, setShow, rowData, searchInput, currentPage }) => {
  const [radioEnable, setRadioEnable] = useState(false);
  const [changeBasic, setChangeBasic] = useState(false);
  const [basicCheckUncheck, setBasicCheckUncheck] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const initialValues = {
    subscription_type: "",
    renew: false,
    duration: "6 months",
    admin_note: "",
    username: "",
    email: "",
    userId: "",
  };

  const dispatch = useDispatch();
  const { approvedUser, userDetail } = useSelector((state) => state.admin);

  useEffect(() => {
    if (show) {
      dispatch(
        getSpecial1OfferUser({
          username: rowData.username,
          userId: rowData.userId,
        })
      );
    }
  }, [rowData.username, rowData.userId, show]);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    if (values.subscription_type === "renew") {
      values.subscription_type = selectedValue ? rowData.subscription_type : "";
      values.renew = selectedValue ? true : false;
    }
    if (
      values.subscription_type === "Basic" &&
      ["Special1", "special1"].includes(rowData.subscription_type)
    ) {
      values.subscription_type = selectedValue
        ? "Basic"
        : rowData.subscription_type;
      values.renew = selectedValue ? true : false;
    }
    try {
      if (["premium", "Premium"].includes(rowData.subscription_type)) {
        values.userId = rowData.userId;
        values.username = rowData.username;
        values.email = rowData.email;
        values.subscription_type = rowData.subscription_type;
        values.duration = approvedUser?.data?.duration ?? values?.duration;
        values.expiry_date_premium = userDetail?.subscription?.data?.end_date;
        dispatch(
          approveSpecial1Subscription({
            values: values,
            searchInput: searchInput,
            page: currentPage,
          })
        );
        resetForm();
        setShow(false);
        setBasicCheckUncheck(false);
        setSelectedValue("");
        setRadioEnable(false);
        dispatch(clearApproveUser(true));
        setSelectedValue("");
        toast.success("Note has been added successfully!");
      } else {
        values.userId = rowData.userId;
        values.username = rowData.username;
        values.email = rowData.email;
        values.subscription_type =
          values.subscription_type === ""
            ? rowData.subscription_type
            : values.subscription_type;
        dispatch(
          approveSpecial1Subscription({
            values: values,
            searchInput: searchInput,
            page: currentPage,
          })
        );
        resetForm();
        setShow(false);
        setBasicCheckUncheck(false);
        setRadioEnable(false);
        dispatch(clearApproveUser(true));
        setSelectedValue("");
        toast.success(
          `Offer has been ${
            values.subscription_type === "Basic" ? "updated" : "approved"
          } successfully!`
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  const handleRadioClick = (value) => {
    if (selectedValue === value) {
      setSelectedValue("");
      setRadioEnable(false);
    } else {
      setSelectedValue(value);
      setRadioEnable(true);
    }
  };

  const handleFilterDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  const handleMonthsDate = (months, startDate) => {
    const date = new Date(startDate);
    const [number] = months.split(" ");

    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + parseInt(number));

    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options)
      .format(newDate)
      .replace(/,/g, "");
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        dispatch(clearApproveUser(true));
        setRadioEnable(false);
        setSelectedValue("");
        setBasicCheckUncheck(false);
      }}
      size="lg"
      className="modal-800 subscription_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mw-100">
          <Row>
            <Col>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  useEffect(() => {
                    if (approvedUser?.data?.duration) {
                      setFieldValue("duration", approvedUser?.data?.duration);
                    }
                    setFieldValue(
                      "subscription_type",
                      rowData?.subscription_type
                    );
                  }, [approvedUser, setFieldValue, rowData?.subscription_type]);
                  return (
                    <Form className="">
                      <Col>
                        <Row className="mb-3">
                          <BootstrapForm.Label className="ml-2 w-100 mb-3">
                            {/* <div className='h5'>Username (Name): </div> */}
                            <div className="text-orange">
                              {rowData?.username +
                                " (" +
                                rowData?.first_name +
                                " " +
                                rowData?.last_name +
                                ")"}
                            </div>
                          </BootstrapForm.Label>
                          <Modal.Title className="h5">
                            Current subscription type:
                          </Modal.Title>
                          <BootstrapForm.Label className="ml-2 mb-0 text-capitalize">
                            {rowData?.subscription_type}
                          </BootstrapForm.Label>
                          {approvedUser?.data?.expiry_date &&
                          ["Special1", "special1"].includes(
                            rowData?.subscription_type
                          ) ? (
                            <BootstrapForm.Label className="ml-2">
                              Expires on{" "}
                              {handleDate(approvedUser?.data?.expiry_date)}
                            </BootstrapForm.Label>
                          ) : ["Premium", "premium"].includes(
                              rowData?.subscription_type
                            ) ? (
                            <BootstrapForm.Label className="ml-2">
                              Expires on{" "}
                              {moment(
                                userDetail?.subscription?.data?.end_date,
                                "ddd, DD MMM YYYY HH:mm:ss z"
                              ).format("dddd MMM DD, YYYY")}
                            </BootstrapForm.Label>
                          ) : null}
                        </Row>
                        {rowData?.subscription_type === "Basic" ||
                        rowData?.subscription_type === "basic" ? (
                          <Row className="align-items-center mb-3">
                            <Col className="col-12">
                              <Modal.Title className="h5">
                                Change it to:
                              </Modal.Title>
                            </Col>
                            <Col className="checkbox-col-custom col-12">
                              <div className="d-flex align-items-center gap-1">
                                <BootstrapForm.Check
                                  type="checkbox"
                                  id="checkbox-special1"
                                  name="checkbox-special1"
                                  className="mt-0"
                                  checked={
                                    values.subscription_type === "Special1"
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                    setRadioEnable(!radioEnable);
                                    setFieldValue(
                                      "subscription_type",
                                      e.target.checked ? "Special1" : ""
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                                <BootstrapForm.Label className="mb-0">
                                  Special1
                                </BootstrapForm.Label>
                              </div>
                            </Col>
                          </Row>
                        ) : rowData?.subscription_type === "Special1" ||
                          rowData?.subscription_type === "special1" ? (
                          <Row>
                            <Row className="v-center">
                              <Col xs={1}>
                                <Field
                                  type="radio"
                                  id="checkbox-basic"
                                  name="subscription_type"
                                  value="Basic"
                                  as={BootstrapForm.Check}
                                  checked={selectedValue === "Basic"}
                                  onClick={() => {
                                    handleRadioClick("Basic");
                                    setRadioEnable(false);
                                    setBasicCheckUncheck(!basicCheckUncheck);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Modal.Title className="h5">
                                  Change to Basic
                                </Modal.Title>
                              </Col>
                            </Row>
                            <Row className="v-center">
                              <Col xs={1}>
                                <Field
                                  type="radio"
                                  id="checkbox-basic"
                                  name="subscription_type"
                                  value="renew"
                                  as={BootstrapForm.Check}
                                  checked={selectedValue === "renew"}
                                  onClick={() => {
                                    handleRadioClick("renew");
                                    setBasicCheckUncheck(false);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Modal.Title className="h5">Renew</Modal.Title>
                              </Col>
                            </Row>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <Modal.Title className="h5">
                                Change it to:
                              </Modal.Title>
                              <BootstrapForm.Label style={{ color: "#ff9668" }}>
                                Can not change a premium type
                              </BootstrapForm.Label>
                            </Col>
                          </Row>
                        )}

                        {["basic", "Basic", "Special1", "special1"].includes(
                          rowData?.subscription_type
                        ) ? (
                          <Row
                            className={`mb-2 ${
                              ["Special1", "special1"].includes(
                                rowData?.subscription_type
                              )
                                ? "ps-3"
                                : ""
                            }`}
                          >
                            <Modal.Title className="h5">{`Duration ${
                              ["Special1", "special1"].includes(
                                rowData?.subscription_type
                              )
                                ? " (from today)"
                                : ""
                            }`}</Modal.Title>
                            <Row>
                              {[1, 1].map((item, index) => (
                                <Col sm={6}>
                                  <Row key={index}>
                                    <Col xs={1}>
                                      <Field
                                        type="radio"
                                        name="duration"
                                        value={
                                          item +
                                          (index > 0 && item === 1
                                            ? " weeks"
                                            : " days")
                                        }
                                        as={BootstrapForm.Check}
                                        disabled={
                                          [
                                            "basic",
                                            "Basic",
                                            "Special1",
                                            "special1",
                                          ].includes(
                                            rowData?.subscription_type
                                          ) && !radioEnable
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <BootstrapForm.Label>
                                        {item +
                                          (index > 0 && item === 1
                                            ? " Week"
                                            : " Day")}
                                      </BootstrapForm.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                              {[1, 2, 3, 4, 5, 6, 9, 1].map((item, index) => (
                                <Col sm={6}>
                                  <Row key={index}>
                                    <Col xs={1}>
                                      <Field
                                        type="radio"
                                        name="duration"
                                        value={
                                          item +
                                          (index > 0 && item === 1
                                            ? " years"
                                            : " months")
                                        }
                                        as={BootstrapForm.Check}
                                        disabled={
                                          [
                                            "basic",
                                            "Basic",
                                            "Special1",
                                            "special1",
                                          ].includes(
                                            rowData?.subscription_type
                                          ) && !radioEnable
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <BootstrapForm.Label>
                                        {item +
                                          (index > 0 && item === 1
                                            ? " year"
                                            : " months")}
                                      </BootstrapForm.Label>
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                            </Row>
                          </Row>
                        ) : null}
                        <Row>
                          <BootstrapForm.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Modal.Title className="h5">
                              Admin notes
                            </Modal.Title>
                            <BootstrapForm.Control
                              as="textarea"
                              rows={3}
                              name="admin_note"
                              value={values.admin_note}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                          </BootstrapForm.Group>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              variant="secondary"
                              onClick={() => {
                                setShow(false);
                                dispatch(clearApproveUser(true));
                                setRadioEnable(false);
                                setSelectedValue("");
                                setBasicCheckUncheck(false);
                              }}
                            >
                              Close
                            </Button>
                            {["Special1", "special1"].includes(
                              rowData?.subscription_type
                            ) ? (
                              <Button
                                variant="primary"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={
                                  basicCheckUncheck ||
                                  (["Special1", "special1"].includes(
                                    rowData?.subscription_type
                                  ) &&
                                    changeBasic)
                                    ? false
                                    : values.admin_note.trim().length < 1 &&
                                      !radioEnable
                                    ? true
                                    : false
                                }
                              >
                                Save Changes
                              </Button>
                            ) : ["basic", "Basic"].includes(
                                rowData?.subscription_type
                              ) ? (
                              <Button
                                variant="primary"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={
                                  ["Special1", "special1"].includes(
                                    rowData?.subscription_type
                                  ) && changeBasic
                                    ? false
                                    : values.admin_note.trim().length < 1 &&
                                      !radioEnable
                                    ? true
                                    : false
                                }
                              >
                                Save Changes
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={values.admin_note.trim().length < 1}
                              >
                                Save Changes
                              </Button>
                            )}
                          </div>
                        </Row>
                      </Col>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
            <Col>
              <Modal.Title className="max-w-285 ms-auto">
                Admin notes:
              </Modal.Title>
              <div className="admin-notes-cs">
                {approvedUser?.data &&
                  approvedUser?.data?.admin_note
                    ?.slice()
                    ?.reverse()
                    ?.map((item, index, array) => (
                      <Card
                        key={index}
                        text="white"
                        className="mb-3 max-w-285 ms-auto border-0"
                      >
                        <Card.Body className="shadow rounded-4">
                          <>
                            {item["date"] && (
                              <Card.Text
                                style={{ color: "#498be1" }}
                                className="mb-1"
                              >
                                {handleFilterDate(item["date"])}
                              </Card.Text>
                            )}
                            {
                              <Card.Text
                                style={{ color: "#7956a3" }}
                                className="mb-1"
                              >
                                {item["Switched to"] === "special1"
                                  ? "Special1"
                                  : item["Switched to"] === "premium"
                                  ? "Premium"
                                  : item["Switched to"]}{" "}
                                |{" "}
                                {item &&
                                !["Basic", "basic"].includes(
                                  item["Switched to"]
                                )
                                  ? item["expiry_date"]
                                    ? ["premium", "Premium"].includes(
                                        item["Switched to"]
                                      )
                                      ? moment(
                                          item["expiry_date"],
                                          "ddd, DD MMM YYYY HH:mm:ss z"
                                        ).format("dddd MMM DD, YYYY")
                                      : handleDate(item["expiry_date"])
                                    : item["Expires in"]
                                    ? handleMonthsDate(
                                        item["Expires in"],
                                        item["date"]
                                      )
                                    : "None"
                                  : "None"}
                              </Card.Text>
                            }

                            {item["note"] ? (
                              <Card.Text
                                style={{ color: "black" }}
                                className="mb-1"
                              >
                                {item["note"]}
                              </Card.Text>
                            ) : (
                              <Card.Text
                                style={{ color: "black" }}
                                className="mb-1"
                              >
                                -
                              </Card.Text>
                            )}
                          </>
                        </Card.Body>
                      </Card>
                    ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePlan;
