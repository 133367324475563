/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { Form as BootstrapForm } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import moment from "moment";

import UniqueChoiceQ from "../../../../components/QuestionsGroup/UniqueChoiceQ";
import TextAnswerQ from "../../../../components/QuestionsGroup/TextAnswerQ";
import DictationQ from "../../../../components/QuestionsGroup/DictationQ";
import MathExpressionQ from "../../../../components/QuestionsGroup/MathExpressionQ";
import MultipleChoiceQ from "../../../../components/QuestionsGroup/MultipleChoiceQ";
import TrueFalseQ from "../../../../components/QuestionsGroup/TrueFalseQ";
import FillImgQ from "../../../../components/QuestionsGroup/FillImgQ";
import FillBlankQ from "../../../../components/QuestionsGroup/FillBlankQ";
import FillGroupQ from "../../../../components/QuestionsGroup/FillGroupQ";
import FillInlineQ from "../../../../components/QuestionsGroup/FillInlineQ";
import MatchItemsQ from "../../../../components/QuestionsGroup/MatchItemsQ";
import MatrixMatchQ from "../../../../components/QuestionsGroup/MatrixMatchQ";
import CustomModal from "../../CustomModal";

const ResolveFlaggedModal = (props) => {
  const validationSchema = Yup.object().shape({
    image_not_showing_well: Yup.boolean(),
    math_symbol_not_showing_correctly: Yup.boolean(),
    question_is_not_showing_well: Yup.boolean(),
    question_has_a_typo: Yup.boolean(),
    question_is_confusing: Yup.boolean(),
    question_seems_wrong: Yup.boolean(),
    i_have_a_suggestion_for_this_question: Yup.boolean(),
    other_issues: Yup.boolean(),
    comment: Yup.string(),
    username: Yup.string(),
    username: Yup.string(),
  });

  const flagArray = [
    { label: "Question has a typo", value: "question_has_a_typo" },
    { label: "Image(s) not showing well", value: "image_not_showing_well" },
    {
      label: "Math symbol(s) not showing correctly",
      value: "math_symbol_not_showing_correctly",
    },
    {
      label: "Question/choice(s) not showing well",
      value: "question_is_not_showing_well",
    },
    { label: "Question seems wrong", value: "question_seems_wrong" },
    { label: "Question is confusing", value: "question_is_confusing" },
    { label: "Question seems incomplete", value: "question_seems_incomplete" },

    {
      label: "I have a suggestion for this question",
      value: "i_have_a_suggestion_for_this_question",
    },
    { label: "Other issues", value: "other_issues" },
  ];

  const initialValues = {
    image_not_showing_well: false,
    math_symbol_not_showing_correctly: false,
    question_is_not_showing_well: false,
    question_has_a_typo: false,
    question_is_confusing: false,
    question_seems_wrong: false,
    question_seems_incomplete: false,
    i_have_a_suggestion_for_this_question: false,
    other_issues: false,
    comment: "",
    username: "",
    flag_timestamp: "",
  };
  const {
    title,
    size,
    content,
    footerButtons,
    show,
    handleClose,
    className,
    flagParams,
    handleChangeComment,
    currentFlag,
    setCurrentFlag,
    confirmResolve,
    setConfirmResolve,
    resolveFlagQuestion,
  } = props;
  const [questionId, setQuestionId] = useState(0);
  const { flagQuestion } = useSelector((state) => state.admin);
  /* question start */

  const handleQuestionsSet = (data) => {
    const item = flagQuestion?.existing_flag?.flags?.[currentFlag];
    if (data?.q_type !== undefined) {
      const { q_type: qType } = data;
      let i = questionId,
        j = questionId,
        k = questionId;
      let props = {};
      props.data = { ...data };
      props.data.ans_obj = item?.ans_obj?.q_id ? item?.ans_obj : "";
      props.fieldValue = props.data.attempted_obj
        ? props?.data?.attempted_obj
        : item?.ans_obj?.ans_key;
      props.indexValue = { i, j, k };

      switch (qType) {
        case "TextAnswerQ":
          return <TextAnswerQ {...props} />;
        case "DictationQ":
          return <DictationQ {...props} />;
        case "MathExpressionQ":
          return <MathExpressionQ {...props} />;
        case "MultipleChoiceQ":
          return <MultipleChoiceQ {...props} />;
        case "UniqueChoiceQ":
          return <UniqueChoiceQ {...props} />;
        case "TrueFalseQ":
          return <TrueFalseQ {...props} />;
        case "FillImgQ":
          return <FillImgQ {...props} />;
        case "FillBlankQ":
          return <FillBlankQ {...props} />;
        case "FillGroupQ":
          return <FillGroupQ {...props} />;
        case "FillInlineQ":
          return <FillInlineQ {...props} />;
        case "MatchItemsQ":
          return <MatchItemsQ {...props} />;
        case "MatrixMatchQ":
          return <MatrixMatchQ {...props} />;
        default:
          return "";
      }
    }
  };
  /* question end */

  const handleFormatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("DD MMM YYYY hh:mm A z");
  };

  const handleCloseCustomModal = () => {
    setConfirmResolve(false);
  };
  const modalTitle = "Confirm to resolve";
  const modalContent = <p>Are you want to resolve this flag</p>;
  const modalFooterButtons = [
    { label: "Cancel", variant: "primary", onClick: handleCloseCustomModal },
    {
      label: "Confirm",
      variant: "primary",
      onClick: resolveFlagQuestion,
    },
  ];

  return (
    flagQuestion?.message && (
      <Modal
        className={className}
        show={show[0]}
        onHide={handleClose}
        //size={size}
        centered
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        <Container className="cs-image-question">
          <Row>
            <Col className="col-md-6">
              <Modal.Body>
                <p>
                  {show[1]?.ans_obj
                    ? `Graded question - score: ${
                        show[1]?.ans_obj?.score_points +
                        "/" +
                        show[1]?.ans_obj?.score_outof
                      }`
                    : "Ungraded question"}
                </p>
                <p>q_id:{" " + show[1]?.q_id}</p>

                {handleQuestionsSet(show[1])}
              </Modal.Body>
            </Col>

            <Col className="col-md-6">
              <Modal.Body>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  // onSubmit={onSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                  }) => {
                    useEffect(() => {
                      if (!show[0]) {
                        resetForm();
                      } else {
                        const flag =
                          flagQuestion?.existing_flag?.flags?.[currentFlag]
                            ?.flag;
                        const item =
                          flagQuestion?.existing_flag?.flags?.[currentFlag];

                        flagArray.forEach((field) =>
                          setFieldValue(field?.value, flag[field?.value])
                        );
                        setFieldValue("comment", flag?.comment);
                        setFieldValue("username", item?.username);
                        setFieldValue("flag_timestamp", item?.flag_timestamp);
                      }
                    }, [flagQuestion.message, currentFlag, flagArray, show[0]]);
                    return (
                      <Container>
                        <Modal.Title className="mb-2">
                          Flagged by: {" " + values.username}
                        </Modal.Title>
                        <p>
                          Date flagged:{" "}
                          {handleFormatDate(values.flag_timestamp)}
                        </p>
                        <Form //onSubmit={handleSubmit}
                        >
                          {flagArray &&
                            flagArray.map((item, index) => (
                              <div className="mb-3" key={index}>
                                <Field
                                  type="checkbox"
                                  id={item.value}
                                  name={item.value}
                                  className="form-check-input me-2"
                                  onChange={handleChange}
                                  disabled
                                />
                                <label className="form-check-label">
                                  {item.label}
                                </label>
                              </div>
                            ))}

                          <BootstrapForm.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                            disabled
                          >
                            <BootstrapForm.Label>
                              Description of the issue
                            </BootstrapForm.Label>
                            {/* <p>{values.comment}</p> */}
                            <Field
                              as="textarea"
                              rows={3}
                              name="comment"
                              className="form-control bg-white border-0 resize-none"
                              disabled
                            />
                          </BootstrapForm.Group>

                          <div className="d-flex">
                            <Button
                              type="button"
                              className="btn-img"
                              variant="outline-secondary"
                              onClick={() => setCurrentFlag((prev) => prev - 1)}
                              disabled={currentFlag === 0}
                            >
                              <img
                                src="/left-arrow.png"
                                alt="left-arrow"
                                className={`arrow-img-icon ${
                                  currentFlag === 0 ? "disabled" : ""
                                }`}
                              />
                            </Button>

                            <p className="mt-3">
                              {currentFlag + 1}/
                              {flagQuestion?.existing_flag?.flags?.length}
                            </p>
                            <Button
                              type="button"
                              className="btn-img"
                              variant="outline-secondary"
                              onClick={() => {
                                setCurrentFlag((prev) => prev + 1);
                              }}
                              disabled={
                                currentFlag + 1 ===
                                flagQuestion?.existing_flag?.flags?.length
                              }
                            >
                              <img
                                src="/right-arrow.png"
                                alt="left-arrow"
                                className={`arrow-img-icon ${
                                  flagQuestion?.existing_flag?.flags?.length ===
                                  currentFlag + 1
                                    ? "disabled"
                                    : ""
                                }`}
                              />
                            </Button>
                          </div>
                        </Form>

                        {footerButtons && (
                          <Modal.Footer>
                            <BootstrapForm.Group
                              className="mb-0 w-100"
                              controlId="exampleForm.ControlTextarea11"
                            >
                              <BootstrapForm.Label className="custom-bold mb-0">
                                Admin Note:
                              </BootstrapForm.Label>

                              {flagQuestion?.existing_flag?.resolved ===
                              false ? (
                                <BootstrapForm.Control
                                  as="textarea"
                                  rows={3}
                                  name="resolution_comment"
                                  value={flagParams?.resolution_comment}
                                  onChange={handleChangeComment}
                                  className="form-control resolution-commit mt-0"
                                />
                              ) : null}
                            </BootstrapForm.Group>
                            {flagQuestion?.existing_flag?.resolved === false &&
                              footerButtons.map((button, index) => (
                                <Button
                                  key={index}
                                  variant={button.variant}
                                  onClick={button.onClick}
                                  // disabled={
                                  //   !flagParams?.resolution_comment?.trim() &&
                                  //   index === 1
                                  // }
                                >
                                  {button.label}
                                </Button>
                              ))}
                          </Modal.Footer>
                        )}
                        {flagQuestion?.existing_flag?.resolved === true ? (
                          <div>
                            <Row>
                              <p className="ms-3">
                                {
                                  flagQuestion?.existing_flag
                                    ?.resolution_comment
                                }
                              </p>
                            </Row>
                            <Row>
                              <Col></Col>
                              <Col className="bg-success-subtle p-3">
                                <p className="ms-2 mb-2">Resolved.</p>

                                <p className="ms-2 mb-0">
                                  {handleFormatDate(
                                    flagQuestion?.existing_flag
                                      .resolved_timestamp
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </Container>
                    );
                  }}
                </Formik>
              </Modal.Body>
            </Col>
          </Row>
          <CustomModal
            title={modalTitle}
            size={"md"}
            content={modalContent}
            footerButtons={modalFooterButtons}
            show={confirmResolve}
            handleClose={handleCloseCustomModal}
            className={"md"}
          />
        </Container>
      </Modal>
    )
  );
};

export default ResolveFlaggedModal;
