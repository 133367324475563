/* eslint-disable */
import Axios from "axios";
import { ApiUrl } from "../utils/config";
import { toast } from "react-toastify";
import { store } from "../redux/store";
import { logout } from "../redux/auth/slice";

const axios = Axios.create({
  baseURL: ApiUrl,
});

const isOnLoginPage = () => window.location.pathname === "/login";
let alreadyRedirected = false;
// Function to handle unauthorized errors
const handleUnauthorized = () => {
  if (!alreadyRedirected) {
    alreadyRedirected = true;
    try {
      store.dispatch(logout());
    } catch (error) {
      console.error("Error dispatching logout:", error);
    }
    localStorage.clear();
    if (!isOnLoginPage()) {
      window.location.href = "/login";
    }
  }
};

// Function to handle unexpected errors
const handleUnexpectedError = () => {
  toast.error("An unexpected error occurred.");
};

// Response Interceptor
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        handleUnauthorized();
        return Promise.reject(error);
      } else if (status === 400) {
        toast.error("Bad request. Please check your input.");
      } else if (status >= 500) {
        toast.error("Internal server error. Please try again later.");
      } else {
        handleUnexpectedError();
      }
    } else {
      handleUnexpectedError();
    }

    return Promise.reject(error);
  }
);

export default axios;
