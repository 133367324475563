/* eslint-disable */
import axios from "./axios";

// Gives authToken
const token = () => localStorage.getItem("userToken");

// Provides Headers
const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
    },
  };
};

// Get Student List
export const getStudentsList = async (data) => {
  const { page = 1 } = data ?? {};
  let url = "";
  if (data.search || data.sort || data.orderBy) {
    url = `admin/students?per_page=10&search=${
      data.search || ""
    }&page=${page}&sort=${data.sort || ""}&orderBy=${data.orderBy || 1}`;
    // url = `admin/students?page=${page}&search=${data.search}`;
  } else {
    url = `admin/students?page=${page}`;
  }
  return await axios
    .get(url, headers())
    .then((res) => res)
    .catch((err) => err);
};

// Get Paid Student
export const paidStudents = async (data) => {
  const { page = 1 } = data;
  let url = "";
  if (data.search) {
    url = `/admin/paid-students?page=${page}&search=${data.search}`;
  } else {
    url = `/admin/paid-students?page=${page}`;
  }
  return await axios
    .get(url, headers())
    .then((res) => res)
    .catch((err) => err);
};

// Get signup info
export const signupInfo = async () => {
  return await axios
    .get("/admin/signup-info", headers())
    .then((res) => res)
    .catch((err) => err);
};

// User details
export const userDetails = async (id, apiType) => {
  return await axios
    .get(`/admin/user-${apiType}?user_id=${id}`, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const approveSpecial1Offer = async (data) => {
  return await axios
    .post("special1/admin/approve/special1_offer", data, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const getSpecial1Offer_user = async (data) => {
  return await axios
    .post("special1/approved/user", data, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const getStudentsListSpecial1Offer = async (params) => {
  const { search, page, limit, sort, orderBy } = params;
  const queryParams = new URLSearchParams({
    search: search || "",
    page: page || 1,
    limit: limit || 10,
    sort: sort || "",
    orderBy: orderBy || "",
  }).toString();

  return await axios
    .get(`special1/offer/users?${queryParams}`, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const getStudentsListSpecial1OfferPending = async (params) => {
  const { search, page, limit, sort, orderBy } = params;
  const queryParams = new URLSearchParams({
    search: search || "",
    page: page || 1,
    limit: limit || 10,
    sort: sort || "",
    orderBy: orderBy || "",
  }).toString();

  return await axios
    .get(`special1/offer/users/pending?${queryParams}`, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const approveDeySpecial1 = async (data) => {
  return await axios
    .put("special1/offer/approve-deny", data, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const adminResetCountsOndemandAdaptive = async (data) => {
  return await axios
    .post("ondemand/admin/reset-counts", data, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const getStudentsFlaggedQuestions = async (params) => {
  const { search, page, limit, sort, orderBy, filter_by } = params;
  const queryParams = new URLSearchParams({
    search: search || "",
    page: page || 1,
    limit: limit || 10,
    sort: sort || "",
    orderBy: orderBy || "",
    filter_by: filter_by,
  }).toString();

  return await axios
    .get(`/flagged/get/flagged-questions?${queryParams}`, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const resolvedFlaggedQuestion = async (params) => {
  return await axios
    .put("/flagged/resolve-flag", params, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const deleteAdminNotes = async (params) => {
  return await axios
    .put("/admin/empty-admin-notes", params, headers())
    .then((res) => res)
    .catch((err) => err);
};

export const deleteSpecial1Approved = async () => {
  return await axios
    .delete("/admin/delete-approved-requests", headers())
    .then((res) => res)
    .catch((err) => err);
};

export const getFlaggedAllQuestions = async (data) => {
  return await axios
    .get(
      `/flagged/get/admin/single-flagged-question?_id=${data._id}&q_id=${data.q_id}&resolved=${data.resolved}`,
      headers()
    )
    .then((res) => res)
    .catch((err) => err);
};

export const deleteAllResolvedFlagQuestions = async () => {
  return await axios
    .delete("/flagged/delete-approved/flagged-questions", headers())
    .then((res) => res)
    .catch((err) => err);
};

export const sendAdminEmailUpdates = async (data) => {
  return await axios
    .post("/dashboard/admin/send-email", data, headers())
    .then((res) => res)
    .catch((err) => err);
};
