/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStudentsList,
  paidStudents,
  userDetails,
  signupInfo,
  approveSpecial1Offer,
  getSpecial1Offer_user,
  getStudentsListSpecial1Offer,
  approveDeySpecial1,
  getStudentsListSpecial1OfferPending,
  adminResetCountsOndemandAdaptive,
  getStudentsFlaggedQuestions,
  resolvedFlaggedQuestion,
  deleteAdminNotes,
  deleteSpecial1Approved,
  getFlaggedAllQuestions,
  deleteAllResolvedFlagQuestions,
  sendAdminEmailUpdates,
} from "../../services/admin";

export const handleGetStudentsList = createAsyncThunk(
  "handleGetStudentsList",
  async (params, { rejectWithValue }) => {
    const response = await getStudentsList(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const handleGetPaidStudent = createAsyncThunk(
  "handleGetPaidStudent",
  async (params, { rejectWithValue }) => {
    const response = await paidStudents(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const handleGetUserDetails = createAsyncThunk(
  "handleGetUserDetails",
  async (params, { rejectWithValue }) => {
    const response = await userDetails(params, "detail");
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const handleGetUserScore = createAsyncThunk(
  "handleGetUserScore",
  async (params, { rejectWithValue }) => {
    const response = await userDetails(params, "score");
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const handleGetSignupInfo = createAsyncThunk(
  "handleGetSignupInfo",
  async (params, { rejectWithValue }) => {
    const response = await signupInfo();
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const approveSpecial1Subscription = createAsyncThunk(
  "approveSpecial1Subscription",
  async ({ values, searchInput, page }, { dispatch, rejectWithValue }) => {
    const response = await approveSpecial1Offer(values);
    if (!response) return rejectWithValue();
    if (searchInput || page) {
      dispatch(handleGetStudentsList({ search: searchInput, page: page }));
    } else {
      dispatch(handleGetStudentsList({}));
    }
    return response.data;
  }
);

export const getSpecial1OfferUser = createAsyncThunk(
  "getSpecial1OfferUser",
  async (data, { rejectWithValue }) => {
    const response = await getSpecial1Offer_user(data);
    if (!response) return rejectWithValue();
    return response.data;
  }
);

export const getStudentsListSpecial1 = createAsyncThunk(
  "getStudentsListSpecial1",
  async (params, { rejectWithValue }) => {
    const response = await getStudentsListSpecial1Offer(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const getStudentsListSpecial1Pending = createAsyncThunk(
  "getStudentsListSpecial1Pending",
  async (params, { rejectWithValue }) => {
    const response = await getStudentsListSpecial1OfferPending(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const approveDeySpecial1Offer = createAsyncThunk(
  "approveDeySpecial1Offer",
  async (data, { dispatch, rejectWithValue }) => {
    const response = await approveDeySpecial1(data);
    if (!response) return rejectWithValue();
    // dispatch(getStudentsListSpecial1({}));
    return { response };
  }
);

export const clearApproveUser = createAsyncThunk(
  "clearApproveUser",
  async (data, { dispatch, rejectWithValue }) => {
    return data;
  }
);

export const adminResetCounts = createAsyncThunk(
  "adminResetCounts",
  async (data, { dispatch, rejectWithValue }) => {
    const response = await adminResetCountsOndemandAdaptive(data);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const getFlaggedQuestions = createAsyncThunk(
  "getFlaggedQuestions",
  async (params, { rejectWithValue }) => {
    const response = await getStudentsFlaggedQuestions(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const resolveFlaggedQuestion = createAsyncThunk(
  "resolveFlaggedQuestion",
  async (params, { rejectWithValue }) => {
    const response = await resolvedFlaggedQuestion(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const deleteAllAdminNotes = createAsyncThunk(
  "deleteAllAdminNotes",
  async (params, { rejectWithValue }) => {
    const response = await deleteAdminNotes(params);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const deleteSpecial1ApprovedRequests = createAsyncThunk(
  "deleteSpecial1ApprovedRequests",
  async (_, { rejectWithValue }) => {
    const response = await deleteSpecial1Approved();
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const getFlagQuestion = createAsyncThunk(
  "getFlagQuestion",
  async (data, { rejectWithValue }) => {
    const response = await getFlaggedAllQuestions(data);
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const deleteResolvedFlagQuestions = createAsyncThunk(
  "deleteResolvedFlagQuestions",
  async (data, { rejectWithValue }) => {
    const response = await deleteAllResolvedFlagQuestions();
    if (!response) return rejectWithValue();
    return { response };
  }
);

export const sendAdminEmail = createAsyncThunk(
  "sendAdminEmail",
  async (data, { rejectWithValue }) => {
    const response = await sendAdminEmailUpdates(data);
    if (!response) return rejectWithValue();
    return { response };
  }
);
