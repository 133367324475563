import React from 'react';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/slice';
import Logo from '../../assets/images/logo.svg';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './style.scss';

const Header = () => {
	const dispatch = useDispatch();

	// Logout
	const logOut = () => {
		localStorage.clear();
		dispatch(logout());
	};

	return (
		<div className="header">
			<Container>
				<Row className="justify-content-between">
					<Col size={2}>
						<NavLink to="/">
							<ReactSVG src={Logo} />
						</NavLink>
					</Col>
					<Col
						size={6}
						className="col d-flex justify-content-end align-items-center"
					>
						<div className="d-flex align-items-center">
							<span className="user_name">
								<NavLink to="/">Home</NavLink>
							</span>
							<span className='user_name'>
								<NavLink to='/controls'>Controls</NavLink>
							</span>
							<Button onClick={logOut} className="header_btn">
                                    Logout
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Header;
