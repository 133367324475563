/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  handleImportPractice,
  handleWebImportPractice,
  handleTimedSetSubmission,
} from "../../redux/practice";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import { Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { adminResetCounts, deleteAllAdminNotes } from "../../redux/admin";
import CustomModal from "../Admin/CustomModal";
const ControlPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAdminCall, setIsAdminCall] = useState(false);
  const [isWebCall, setIsWebCall] = useState(false);
  const [isTimedSubmitCall, setIsTimedSubmitCall] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    loading,
    importPracticeResponse,
    importWebPracticeResponse,
    timedSetSubmissionResponse,
  } = useSelector((state) => state.practice);

  useEffect(() => {
    if (isAdminCall) {
      if (
        importPracticeResponse &&
        importPracticeResponse.status_code === 200
      ) {
        setIsAdminCall(false);
        toast.success(importPracticeResponse.message);
      } else if (importPracticeResponse && importPracticeResponse.error) {
        setIsAdminCall(false);
        toast.error(importPracticeResponse.message);
      }
    }
  }, [importPracticeResponse]);

  useEffect(() => {
    if (isWebCall) {
      if (
        importWebPracticeResponse &&
        importWebPracticeResponse.status_code === 200
      ) {
        setIsWebCall(false);
        toast.success(importWebPracticeResponse.message);
      } else if (importWebPracticeResponse && importWebPracticeResponse.error) {
        setIsWebCall(false);
        toast.error(importWebPracticeResponse.message);
      }
    }
  }, [importWebPracticeResponse]);

  useEffect(() => {
    if (isTimedSubmitCall) {
      if (
        timedSetSubmissionResponse &&
        timedSetSubmissionResponse.status_code === 200
      ) {
        setIsTimedSubmitCall(false);
        toast.success(timedSetSubmissionResponse.message);
      } else if (
        timedSetSubmissionResponse &&
        timedSetSubmissionResponse.error
      ) {
        setIsTimedSubmitCall(false);
        toast.error(timedSetSubmissionResponse.message);
      }
    }
  }, [timedSetSubmissionResponse]);

  if (loading) {
    return <div className="loader_section">Loading...</div>;
  }

  const deleteAllAdminNotesSection = () => {
    dispatch(deleteAllAdminNotes(true))
      .unwrap()
      .then((res) => {
        if (res?.response?.status === 200) {
          toast.success(res?.response?.data?.message);
        } else {
          toast.success("Some thing went wrong!");
        }
      });
    setShowConfirmModal(false);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const modalTitle = "Delete";
  const modalContent = <p>Are you want to delete admin notes of all users!</p>;
  const modalFooterButtons = [
    { label: "Close", variant: "danger", onClick: handleCloseConfirmModal },
    {
      label: "Delete",
      variant: "primary",
      onClick: deleteAllAdminNotesSection,
    },
  ];

  return (
    <div className="control_page">
      <Header />
      <section className="banner_section">
        <Container>
          <div className="banner_wrapper">
            <div className="banner_content">
              <h1>
                <span>Controls</span>
              </h1>
            </div>
          </div>
        </Container>
      </section>
      <div className="control_actions">
        <Container>
          <div className="control_btns">
            <div className="mb-4 w-100">
              <h2 className="mb-4 pb-2 border-bottom">Practice data</h2>
              <div className="d-flex justify-content-start gap-5">
                <div>
                  <Button
                    className="py-2 w-100"
                    onClick={() => {
                      dispatch(handleImportPractice());
                      dispatch(adminResetCounts(true));
                      setIsAdminCall(true);
                    }}
                  >
                    Get new data for Admin
                  </Button>
                </div>
                <div>
                  <Button
                    className="py-2 w-100"
                    onClick={() => {
                      dispatch(handleWebImportPractice());
                      setIsWebCall(true);
                    }}
                  >
                    Get new data for Web
                  </Button>
                </div>
              </div>
            </div>
            <div className="mb-4 w-100">
              <h2 className="mb-4 pb-2 border-bottom">Grading</h2>
              <div className="d-flex justify-content-start gap-5">
                <div>
                  <Button
                    className="py-2 w-100"
                    onClick={() => {
                      dispatch(handleTimedSetSubmission());
                      setIsTimedSubmitCall(true);
                    }}
                  >
                    Submit Timed Sets
                  </Button>
                </div>
              </div>
            </div>
            <div className="mb-0 w-100">
              <h2 className="mb-4 pb-2 border-bottom">Form submissions</h2>
              <div>
                <Button
                  className="border-0 p-0 w-auto bg-transparent text-blue text-orange-hover"
                  onClick={() => navigate("/controls/special1-requests")}
                >
                  Special1 Requests
                </Button>
              </div>
            </div>

            <div className="mb-0 w-100">
              <div>
                <Button
                  className="border-0 p-0 w-auto bg-transparent text-blue text-orange-hover"
                  onClick={() => navigate("/controls/flagged-questions")}
                >
                  Flagged Questions
                </Button>
              </div>
            </div>

            <div className="mb-0 w-100">
              <div>
                <Button
                  className="border-0 p-0 w-auto bg-transparent text-blue text-orange-hover"
                  onClick={() => navigate("/controls/admin-email")}
                >
                  Send Admin Email
                </Button>
              </div>
            </div>

            <div className="mb-4 w-100">
              <div className="d-flex justify-content-start gap-5">
                <div>
                  <Button
                    className="py-2 w-100 custom-button-delete-tab"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    Delete Admin Notes
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <CustomModal
            size={"md"}
            title={modalTitle}
            content={modalContent}
            footerButtons={modalFooterButtons}
            show={showConfirmModal}
            handleClose={handleCloseConfirmModal}
          />
        </Container>
      </div>
      <div className="footer">
        <h5 className="text-center">
          &copy; {new Date().getFullYear()} boo9 inc.
        </h5>
      </div>
    </div>
  );
};

export default ControlPage;
