/* eslint-disable */
import {
  Body,
  Button,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Preview,
  Section,
  Text,
  userFirstname,
} from "@react-email/components";
import * as React from "react";

const EmailPreview = ({ subject, message }) => {
  return (
    <Html>
      <Head />
      <Body style={main}>
        <Container style={container}>
          <Section>
            <Text style={paragraph}>Subject: {subject},</Text>
            <Text style={paragraph}>Hi @username, </Text>
          </Section>
          <Section>
            <Text style={paragraph_text}>{message}</Text>
          </Section>

          <Hr style={hr} />
          <Text style={footer}>
          <span>&copy; {(new Date().getFullYear())} boo9 inc. | boo9&trade; | InteLa&trade;</span>
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

export default EmailPreview;

const main = {
  backgroundColor: "#ffffff",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const paragraph_text = {
  fontSize: "16px",
  lineHeight: "26px",
  whiteSpace: "pre-wrap",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
};
