/* eslint-disable */

import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { handleGetUserDetails } from "../../../redux/admin";
import "./style.scss";
import Header from "../../../components/Header";

const userDetails = () => {
  const { loading, userDetail } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const location = useLocation();
  const existingValues = location.state;

  useEffect(() => {
    dispatch(handleGetUserDetails(existingValues));
  }, []);

  if (loading) {
    return (
      <div className="loader_section">
        <h6>Loading...</h6>
      </div>
    );
  }

  if (userDetail && userDetail.error) {
    return (
      <div className="loader_section error">
        <p>
          {userDetail.message.user_id
            ? userDetail.message.user_id[0]
            : userDetail.message || "Please try after some time"}
        </p>
      </div>
    );
  }

  const handleDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  return (
    <div className="dashboard_wrapper">
      <Header />
      {userDetail && (
        <div className="signupinfo_wrapper">
          <Container>
            <div className="userbox">
              <h5>Personal Information</h5>
              <div className="userbox_content">
                <div className="user_info">
                  <strong>Name:</strong>
                  <span>
                    {userDetail.first_name} {userDetail.last_name}
                  </span>
                </div>
                <div className="user_info">
                  <strong>Email:</strong>
                  <span>{userDetail.email}</span>
                </div>
                <div className="user_info">
                  <strong>Username:</strong>
                  <span>{userDetail.username}</span>
                </div>
                <div className="user_info">
                  <strong>ID:</strong>
                  <span>{userDetail.id}</span>
                </div>
                <div className="user_info">
                  <strong>Sign-up date:</strong>
                  <span>{userDetail.date_signed_up}</span>
                </div>
              </div>
            </div>
            <div className="userbox">
              <h5>Subscription</h5>
              <div className="userbox_content">
                <>
                  <div className="user_info">
                    <strong>Satus:</strong>
                    <span className="text-captalize">
                      {userDetail?.user_data?.subscription_type}
                    </span>
                  </div>
                  <div className="user_info">
                    <strong>Auto Recurring:</strong>
                    <span>
                      {["Special1", "special1", "Basic", "basic"].includes(
                        userDetail?.user_data?.subscription_type
                      )
                        ? "NA"
                        : userDetail?.subscription?.data?.auto_recurring
                        ? "On"
                        : "Off"}
                    </span>
                  </div>
                  <div className="user_info">
                    <strong>Subscription Start:</strong>
                    <span>
                      {["Basic", "basic"].includes(
                        userDetail?.user_data?.subscription_type
                      )
                        ? "--"
                        : userDetail?.user_data.start_subscription_date &&
                          ["Special1", "special1"].includes(
                            userDetail?.user_data?.subscription_type
                          )
                        ? handleDate(
                            userDetail?.user_data?.start_subscription_date
                          )
                        : userDetail?.subscription?.data?.start_date
                        ? moment(
                            userDetail?.subscription?.data?.start_date,
                            "ddd, DD MMM YYYY HH:mm:ss z"
                          ).format("dddd MMM DD, YYYY")
                        : "--"}
                    </span>
                  </div>
                  <div className="user_info">
                    <strong>Subscription End:</strong>
                    <span>
                      {["Basic", "basic"].includes(
                        userDetail?.user_data?.subscription_type
                      )
                        ? "--"
                        : userDetail?.user_data?.expiry_date &&
                          ["Special1", "special1"].includes(
                            userDetail?.user_data?.subscription_type
                          )
                        ? handleDate(userDetail?.user_data?.expiry_date)
                        : userDetail?.subscription?.data?.end_date
                        ? moment(
                            userDetail?.subscription?.data?.end_date,
                            "ddd, DD MMM YYYY HH:mm:ss z"
                          ).format("dddd MMM DD, YYYY")
                        : "--"}
                    </span>
                  </div>
                </>
              </div>
            </div>
            <div className="userbox">
              <h5>Login History</h5>
              <div className="userbox_content">
                <div className="login_history">
                  {userDetail.last_login_data.map((login, index) => (
                    <span key={index}>
                      {moment(
                        login.login_time,
                        "ddd, DD MMM YYYY HH:mm:ss z"
                      ).format("dddd MMM DD, YYYY")}{" "}
                      from {login.city}, {login.country}
                    </span>
                  ))}
                  {userDetail.last_login_data < 1 && <p>No data found</p>}
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default userDetails;
